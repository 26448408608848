import {
  BudgetImportsIcon,
  ChinaImportsIcon,
  ExportByAirIcon,
  ExportBySeaIcon,
  ImportBySeaIcon,
  ImportBySeatIcon,
  Shop1,
  Shop2,
  Shop3,
  Shop4,
  ShopOnlineIcon,
  PlugIcon,
  ApiIcon
} from './asset-contants';

export const OurServicesLists = {
  import: [
    {
      image: ImportBySeaIcon,
      title: 'Import by Sea',
      content:
        'Ship heavy and oversized items via sea freight and receive your items in 8-12 weeks.'
    },
    {
      image: BudgetImportsIcon,
      title: 'Budget Imports',
      content: 'Enjoy customs clearance-free shipping when you choose Budget!'
    },
    {
      image: ChinaImportsIcon,
      title: 'China Imports',
      content: `Import from China’s retailers and manufacturers. Delivered affordably to Nigeria.`
    }
  ],
  export: [
    {
      image: ExportBySeaIcon,
      title: 'Export by Sea',
      content:
        'Send heavy and oversized items via sea freight, delivered in 8-12 weeks.'
    },
    {
      image: ExportByAirIcon,
      title: 'Export by Air',
      content:
        'Quick and reliable air freight shipping options for businesses and individuals.'
    }
  ],
  shopOnline: [
    {
      image: ShopOnlineIcon,
      title: 'Shop and Ship',
      content:
        'Shop online and ship to an address in the USA, UK, Canada, South Africa, and more. Delivered to your doorstep in Nigeria.'
    }
  ],
  pluginAndApi: [
    {
      image: PlugIcon,
      title: 'Plugin',
      content:
        'Say goodbye to booking customer deliveries manually. Compatible with WordPress, WooCommerce, Shopify, and more. No coding is required.',
      buttonText: 'Get Plugin',
      cardUrl: 'https://topship.africa/plug-ins'
    },
    {
      image: ApiIcon,
      title: 'API',
      content:
        'Integrate global shipping into your app or website. Send custom branded shipment notifications to your customers. Developer-friendly.',
      buttonText: 'Access Documentation',
      cardUrl: 'https://topship.africa/api'
    }
  ]
};

export const shopNShipFeature = {
  image: ShopOnlineIcon,
  contents: [
    {
      icon: Shop1,
      label: 'Starts from $20 per kg'
    },
    {
      icon: Shop2,
      label: 'Delivery in 7 - 14 working days'
    },
    {
      icon: Shop3,
      label:
        'Shop from online stores and deliver to an address in Canada, the UK, USA, South Africa, and more'
    },
    {
      icon: Shop3,
      label: 'Doorstep delivery (FREE if within Lagos)'
    }
  ],
  info: {
    icon: Shop4,
    label:
      'Packages are shipped out once a week (Fridays only). Delivery timeline begins the following week after your package has been delivered to our Canada, UK or USA partner Drop-off centers'
  }
};

export const shopNShipService = {
  image: ShopOnlineIcon,
  contents: [
    {
      icon: Shop1,
      label: 'Starts from $20 per kg'
    },
    {
      icon: Shop2,
      label: 'Delivery in 7 - 14 working days'
    },
    {
      icon: Shop3,
      label:
        'Shop from online stores and deliver to an address in Canada, the UK, USA, South Africa, and more'
    },
    {
      icon: Shop3,
      label: 'Doorstep delivery (FREE if within Lagos)'
    }
  ],
  info: {
    icon: Shop4,
    label:
      'Packages are shipped out once a week (Fridays only). Delivery timeline begins the following week after your package has been delivered to our Canada, UK or USA partner Drop-off centers'
  }
};

export const ChinaImportService = {
  image: ChinaImportsIcon,
  contents: [
    {
      icon: Shop1,
      label: 'Starts from $15 per kg'
    },
    {
      icon: Shop2,
      label: 'Delivery take 10 - 15 working days (Non-liquid items)'
    },
    {
      icon: Shop2,
      label: 'Delivery take 15 - 25 working days (Liquid items)'
    },
    {
      icon: Shop3,
      label: 'Ship from suppliers, manufacturers in China'
    },
    {
      icon: Shop3,
      label: 'Inclusive of customs clearance charges'
    },
    {
      icon: Shop3,
      label: 'No doorstep delivery'
    }
  ],
  info: {
    icon: Shop4,
    label: 'Pick-ups and doorstep delivery are charged separately'
  }
};

export const BudgetImportService = {
  image: BudgetImportsIcon,
  contents: [
    {
      icon: Shop1,
      label: 'Starts from $12 per kg'
    },
    {
      icon: Shop2,
      label: 'Delivery in 7 - 15 working days'
    },
    {
      icon: Shop3,
      label: 'Door-to-door imports from the USA (more routes are coming soon!)'
    },
    {
      icon: Shop3,
      label: 'Price is inclusive of customs clearance fees.'
    }
  ],
  info: {
    icon: Shop4,
    label: 'Pick-up charged seperately'
  }
};

export const ImportsBySeaService = {
  image: ImportBySeaIcon,
  contents: [
    {
      icon: Shop1,
      label: 'Request a price quote'
    },
    {
      icon: Shop2,
      label: 'Delivery timeline depends on pick up and drop off addresses'
    },
    {
      icon: Shop3,
      label: 'No Minimum Weight'
    },
    {
      icon: Shop3,
      label: 'Inclusive of customs clearance charges'
    },
    {
      icon: Shop4,
      label: 'Doorstep delivery is included in price (unless stated otherwise)'
    }
  ]
};

export const ExportsBySeaUKService = {
  image: ExportBySeaIcon,
  contents: [
    {
      icon: Shop1,
      label: '£4.00 per kg'
    },
    {
      icon: Shop2,
      label:
        'Items are shipped once a month and delivery timeline is 2 - 3 months from shipment date'
    },
    {
      icon: Shop3,
      label: 'Pick up from warehouse in London'
    },
    {
      icon: Shop3,
      label: 'Doorstep delivery available on request'
    },
    {
      icon: Shop4,
      label: 'Minimum Weight of 100 kg'
    },
    {
      icon: Shop4,
      label: 'Price is Inclusive of customs clearance charges'
    }
  ]
};

export const ExportsBySeaUSAService = {
  image: ExportBySeaIcon,
  contents: [
    {
      icon: Shop1,
      label: '₦600 per kg'
    },
    {
      icon: Shop2,
      label:
        'Items are shipped once a month and delivery timeline is 2 - 3 months from shipment date'
    },
    {
      icon: Shop3,
      label:
        'Pick up from warehouse in New Jersey, Atlanta, Maryland and Dallas'
    },
    {
      icon: Shop3,
      label: 'Doorstep delivery available on request'
    },
    {
      icon: Shop4,
      label: 'Minimum Weight of 100 kg'
    },
    {
      icon: Shop4,
      label: 'Not inclusive of customs clearance charges.'
    }
  ]
};

export const ExportsByAirBudget = {
  image: ExportByAirIcon,
  contents: [
    {
      icon: Shop1,
      label: 'Delivery in 7 - 15 working days'
    },
    {
      icon: Shop2,
      label: 'Doorstep Delivery'
    },
    {
      icon: Shop3,
      label: 'Inclusive of customs clearance charges'
    }
  ],
  info: {
    icon: Shop3,
    label: 'Best used for heavy packages.'
  }
};

export const ExportsByAirExpress = {
  image: ExportByAirIcon,
  contents: [
    {
      icon: Shop1,
      label: 'Delivery in 3 - 7 working days'
    },
    {
      icon: Shop2,
      label: 'Ships to 150+ cities worldwide (including cities in Nigeria)'
    },
    {
      icon: Shop3,
      label: 'Doorstep Delivery'
    },
    {
      icon: Shop1,
      label: 'Not inclusive of customs clearance charges'
    }
  ]
};

export const ExportsByAirSaver = {
  image: ExportByAirIcon,
  contents: [
    {
      icon: Shop1,
      label: 'Delivery in 7 - 10 working days'
    },
    {
      icon: Shop2,
      label: 'Ships to 150+ cities worldwide (including cities in Nigeria)'
    },
    {
      icon: Shop3,
      label: 'Doorstep Delivery'
    },
    {
      icon: Shop1,
      label: 'Not inclusive of customs clearance charges'
    }
  ]
};
