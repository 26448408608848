import React, { useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ReactGA from 'react-ga';
import { useRecoilState } from 'recoil';
import {
  quoteRequestState,
  quotesAddressesState,
  blackFridayState,
  selectedBlackFridayState,
  shipmentRequestState,
  shopNShipResultState,
  verificationStepsState,
  userState,
  chinaImportState,
  setShowTrackingNoPrompt,
  openRoundUpPromptState
} from '../../recoil/atoms';
import { useGetShopnshipsQuery } from '../../operations/queries';
import ReactTooltip from 'react-tooltip';
import bookShipment from '../../assets/svg/book-shipment.svg';
import trackShipment from '../../assets/svg/track-shipment-2.svg';
import { ReactComponent as RoundupBgIcon } from '../../assets/svg/roundup-bg-icon.svg';
import getQuote from '../../assets/svg/get-quote.svg';
import shopNShip from '../../assets/svg/shop-n-ship.svg';
import InviteRed from '../../assets/image/alarm-red.png';

import viewAllArrow from '../../assets/svg/view-all-arrow.svg';
import walletIcon from '../../assets/svg/blue-wallet.svg';
import blueArrow from '../../assets/svg/blue-arrow.svg';

import {
  useGetWalletBalanceQuery,
  useGetUserShipmentsQuery,
  useGetCurrentMonthShipments,
  useGetUserFinalShopnshipChargesQuery,
  useGetUserLazyQuery,
  useUserRoundupDataQuery
} from '../../operations/queries.js';
import BusyOverlay from '../../components/busy-overlay';
import numberWithComma from '../../utilities/number-with-commas';
import { Helmet } from 'react-helmet';
import Wrapper from '../../components/Layouts/wrapper';

import RecentShipment from '../../components/Commons/recent-shipment';
import NewFundWalletModal from '../../components/modals/new-fund-wallet-modal';
import NewTrackingModal from '../../components/modals/new-tracking-modal';
import NewGetQuotePrompt from '../../components/modals/new-get-quote-prompt';
import PastShipmentLoader from './PastShipments/past-shipment-loader';
import BookShipmentModal from '../../components/modals/book-shipment-modal';
import ShipmentRequest from '../../components/book-shipment-options';
import EmptyList from '../../components/empty-list';
import { useGetUserPayload } from '../../utilities/get-payload';
import PaymentMethodPrompt from '../../components/modals/payment-method-prompt';
import { useSendAlert } from '../../utilities/send-alert';
import { shopNShipLinks } from '../../constants/shopnship-links';
import insurancePlans from '../../constants/insurance-plans';
import PastShipmentDetailsModal from './PastShipments/past-shipment-details-modal';
import DashboardCarousel from '../../components/dashboard-carousel';
import shipmentMetricDurations from '../../components/Commons/shipment-metric';
import MonthlyShipmentCount from '../../components/shipment-count';
import emptyStateIcon from '../../assets/svg/empty.svg';
import PendingTransactions from './AdditionalCharges/pending-transactions';
// import { accountType } from '../../constants/authentication';
import { WarningAlert, WhiteArrowIcon } from '../../constants/asset-contants';
import VerificationPercentage from './Settings/modals/Verification/components/verification-progress';
import VerificationModal from './Settings/modals/Verification/verification.modal';
import { useResendOTPMutation } from '../../operations/mutations';
import { toFixed } from '../../utilities/to-fixed';
import { currencies } from '../../constants/currencies';
import SelectCurrencyPrompt from '../../components/modals/select-currency-prompt';
import RoundupModalWrapper from '../../components/Layouts/roundup-modal-wrapper';
import {
  shouldShowLocationPrompt,
  shouldShowLspPrompt
} from './helper-functions';
import PendingShopnship from '../../components/pending-shopnship.jsx';
import { Box } from '@mui/material';
import TrackingIdModal from '../../components/modals/tracking-id-update-modal.jsx';
import CompleteOrderTracking from '../../components/complete-order-tracking-no-update.jsx';
import SurveyPromptModal from '../../components/survey-prompt-modal.jsx';
import ServiceClosureBanner from '../../components/service-closure-banner.jsx';
import { ReactComponent as RightArrow } from '../../assets/svg/right-arrow-icon.svg';
import { ReactComponent as BlueSqaureShape } from '../../assets/svg/blue-square-space-icon.svg';
import { ReactComponent as BlueWordPress } from '../../assets/svg/blue-wordpress-icon.svg';
import { ReactComponent as BlueShopify } from '../../assets/svg/blue-shopify-icon.svg';
import YearRoundUp from '../../components/modals/year-round-up.jsx';

const Dashboard = () => {
  const history = useHistory();

  const [openFundWalletModal, setOpenFundWalletModal] = useState(false);
  const [shipmentItem, setShipmentItem] = useState(null);
  const [openShipmentModal, setOpenShipmentModal] = useState(false);
  const [openTrackingModal, setOpenTrackingModal] = useState(false);
  const [openBookShipmentModal, setOpenBookShipmentModal] = useState(false);
  const [, setOpenShopNShipPrompt] = useState(false);
  // const [showTrackingModal, setShowTrackingModal] = useState(false);
  const [shipmentId, setShipmentId] = useState('');
  const [openGetQuotePrompt, setOpenGetQuotePrompt] = useState(false);
  const [paymentID, setPaymentID] = useState('');
  const [openPaymentMethodPrompt, setOpenPaymentMethodPrompt] = useState(false);
  const [shipmentDuration] = useState(shipmentMetricDurations[0]);
  const [openCurrencyPrompt, setOpenCurrencyPrompt] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [, setIsChinaImport] = useRecoilState(chinaImportState);

  const [
    getCurrentMonthShipments,
    getCurrentMonthShipmentsResult
  ] = useGetCurrentMonthShipments();
  const user = useGetUserPayload();
  const [, setQuotesAddresses] = useRecoilState(quotesAddressesState);
  const [, setRequestQuotesPage] = useRecoilState(quoteRequestState);
  const [, setIsBlackFriday] = useRecoilState(blackFridayState);
  const [, setSelectedBlackFridayOption] = useRecoilState(
    selectedBlackFridayState
  );

  const sendAlert = useSendAlert();
  const [getUser, { data: userData }] = useGetUserLazyQuery(
    undefined,
    undefined,
    true
  );
  const [openVerificationModal, setOpenVerificationModal] = useState(false);
  const [lspUserDetails] = useRecoilState(userState);

  const [openRoundUpPrompt, setOpenRoundUpPrompt] = useRecoilState(
    openRoundUpPromptState
  );

  const [
    getUserRoundupData,
    getUserRoundupDataResult
  ] = useUserRoundupDataQuery();

  const [, setRequestPage] = useRecoilState(shipmentRequestState);
  const [, setShopnshipResult] = useRecoilState(shopNShipResultState);
  const [
    getFinalCharges,
    getFinalChargesResult
  ] = useGetUserFinalShopnshipChargesQuery(data => {
    if (data) {
      setShopnships(data?.getUserShopnshipFinalCharges);
    }
  });
  const [shopnships, setShopnships] = useState([]);
  const [showServiceClosure, setShowServiceClosure] = useState(true);
  const take = 5;
  const walletBalance = useGetWalletBalanceQuery();
  const [getShipments, shipments] = useGetUserShipmentsQuery();
  const { data, loading: shipmentsLoading } = shipments;
  const [resendOtp, resendOtpResult] = useResendOTPMutation();

  const getShopnshipsQuery = useGetShopnshipsQuery();

  const loading =
    shipmentsLoading ||
    resendOtpResult.loading ||
    getFinalChargesResult.loading ||
    getCurrentMonthShipmentsResult.loading ||
    getUserRoundupDataResult.loading;

  const businessId = localStorage.getItem('lspId');

  const resetBlackFridayOptions = () => {
    setIsBlackFriday(false);
    setSelectedBlackFridayOption({});
    setRequestPage(0);
  };

  const handlePayShopnship = id => {
    if (!id) {
      return;
    }

    setPaymentID(`${id}/Final`);

    const shopnship = shopnships.find(shipment => shipment?.id === id);

    setShopnshipResult(shopnship);

    setOpenCurrencyPrompt(true);
  };

  const handlePromptSuccess = () => {
    setOpenPaymentMethodPrompt(false);

    sendAlert('Payment Successful!', 'success');

    setShopnships(
      shopnships.filter(shopnship => shopnship.id !== paymentID.split('/')[0])
    );
  };

  const [steps, setSteps] = useRecoilState(verificationStepsState);
  const newSteps = useMemo(
    () =>
      steps.map((step, index) => {
        if (index === 0) {
          return { ...step, isCompleted: lspUserDetails?.isEmailVerified };
        } else if (index === 1) {
          return {
            ...step,
            isCompleted: !!lspUserDetails?.businessInformation?.location
          };
        } else if (index === 2) {
          return {
            ...step,
            isCompleted: !!lspUserDetails?.businessInformation?.compliance
          };
        } else if (index === 3) {
          return {
            ...step,
            isCompleted: lspUserDetails?.businessInformation?.directors?.length
          };
        } else {
          return {
            ...step,
            isCompleted: lspUserDetails?.businessInformation?.directors?.every(
              director => !!director?.compliance
            )
          };
        }
      }),
    [steps, lspUserDetails]
  );
  const completedCount = useMemo(
    () => newSteps.filter(step => step.isCompleted).length,
    [newSteps]
  );
  const isCompletedButUnverified = useMemo(
    () =>
      newSteps.every(step => step.isCompleted) &&
      !lspUserDetails.businessInformation?.isVerified,
    [lspUserDetails, newSteps]
  );

  const percentage = Math.round((completedCount / 6) * 100);
  const handleSteps = () => {
    setSteps(newSteps);

    if (!newSteps[0].isCompleted) {
      resendOtp(lspUserDetails?.id);
    }

    setOpenVerificationModal(true);
  };

  const hasEmptyTrackingNumber = shopnshipList => {
    for (const shopnship of shopnshipList) {
      if (
        shopnship.status === 'PaymentPending' ||
        shopnship.status === 'Confirmed'
      ) {
        const itemDescriptions = JSON.parse(shopnship.itemDescription);
        if (itemDescriptions.some(item => !item.trackingNumber)) {
          return true;
        }
      }
    }
    return false;
  };

  useEffect(
    () => {
      // const paymentSuccessful = localStorage.getItem('justPaid');
      // setShowTrackingModal(paymentSuccessful);
      getUser();
      getUserRoundupData();
      getCurrentMonthShipments(shipmentDuration.value);
      resetBlackFridayOptions();
      getFinalCharges();
      getShipments({
        take
      });
      setIsChinaImport(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    const getShopnships = async () => {
      const data = await getShopnshipsQuery(1, 10);

      const shopnshipList = await data?.getShopnships?.list;

      if (hasEmptyTrackingNumber(shopnshipList || [])) {
        setShowPrompt(true);
      } else {
        setShowPrompt(false);
      }
    };

    getShopnships();
  }, [loading]);
  // }, [showTrackingModal]);

  useEffect(() => {
    getCurrentMonthShipments(shipmentDuration.value);
    // eslint-disable-next-line
  }, [shipmentDuration]);

  useEffect(() => {
    const targetDate = '2025-02-08T00:00:00';
    const targetTime = new Date(targetDate).getTime();
    const currentTime = new Date().getTime();
    if (currentTime > targetTime) {
      setShowServiceClosure(false);
    }
  }, []);

  return (
    <>
      <SurveyPromptModal shipments={data?.getShipments.list} />
      {/* <YearRoundUp /> */}
      {/* {showTrackingModal && (
        <TrackingIdModal
          setShowTrackingModal={setShowTrackingModal}
          shipmentId={shipmentId}
        />
      )} */}
      {openShipmentModal && (
        <PastShipmentDetailsModal
          openModal={openShipmentModal}
          setOpenModal={setOpenShipmentModal}
          shipmentItem={shipmentItem}
          setShipmentItem={setShipmentItem}
          currentPage={1}
        />
      )}
      <RoundupModalWrapper
        openRoundUpPrompt={openRoundUpPrompt}
        setOpenRoundUpPrompt={setOpenRoundUpPrompt}
      />
      <VerificationModal
        openModal={openVerificationModal}
        setOpenModal={setOpenVerificationModal}
      />
      <BookShipmentModal
        openModal={openBookShipmentModal}
        setOpenModal={setOpenBookShipmentModal}
        setOpenPrompt={setOpenShopNShipPrompt}
      >
        <ShipmentRequest />
      </BookShipmentModal>
      <NewFundWalletModal
        openModal={openFundWalletModal}
        setOpenModal={setOpenFundWalletModal}
      />
      <NewTrackingModal
        openModal={openTrackingModal}
        setOpenModal={setOpenTrackingModal}
      />
      <NewGetQuotePrompt
        openPrompt={openGetQuotePrompt}
        setOpenPrompt={setOpenGetQuotePrompt}
        setOpenBookShipmentModal={setOpenBookShipmentModal}
      />
      <PaymentMethodPrompt
        isPendingShopnship
        paymentID={paymentID}
        transactionType='Shopnship'
        openPrompt={openPaymentMethodPrompt}
        setOpenPrompt={setOpenPaymentMethodPrompt}
        onPromptSuccess={handlePromptSuccess}
      />

      <SelectCurrencyPrompt
        openPrompt={openCurrencyPrompt}
        setOpenPrompt={setOpenCurrencyPrompt}
        onOpenPaymentPrompt={({ currency }) => {
          setOpenCurrencyPrompt(false);

          if (currency === currencies[0].currency) {
            setOpenPaymentMethodPrompt(true);
          } else {
            history.push(
              `/shipment-request-foreign-exchange-payment?shopnshipID=${paymentID}/Final&currency=${currency}`
            );
          }
        }}
      />

      <div className='dashboard'>
        <Helmet>
          <meta name='description' content='Topship Africa Dashboard' />
          <title>Topship Africa Dashboard</title>
          <link rel='canonical' href='https://topship.africa/dashboard' />
        </Helmet>
        <Wrapper>
          <BusyOverlay loading={loading} />
          <div aria-label='Welcome Screen' className='dashboard-container'>
            <div
              className='plugin__prompt'
              onClick={() => history.push('/services')}
            >
              <div className='plugin__prompt__icons'>
                <BlueSqaureShape className='plugin__prompt__icon' />
                <BlueWordPress className='plugin__prompt__icon' />
                <BlueShopify className='plugin__prompt__icon' />
              </div>
              <p className='plugin__prompt__text'>Connect Your Online Store</p>
              <RightArrow className='plugin__prompt__icon' />
            </div>
            {showPrompt ? <CompleteOrderTracking /> : ''}
            <div className='roundup__prompt-and-service__closure-banner'>
              <div className='roundup__prompt-CTA--container'>
                <div className='roundup__prompt-texts'>
                  <p className='roundup__promp-main--text'>
                    How did you ship with us this year?
                  </p>
                  <p className='roundup__promp-sub--text'>
                    Check out your shipping roundup and see how you moved your
                    world in 2024!
                  </p>
                </div>
                <button
                  className='year__rounup-CTA'
                  onClick={() => setOpenRoundUpPrompt(true)}
                >
                  <p className='roundup__CTA-text'>See Roundup</p>
                </button>
                <RoundupBgIcon className='roundup__bg-icon' />
              </div>
              {showServiceClosure ? <ServiceClosureBanner /> : ''}
            </div>
            {shouldShowLocationPrompt(userData?.user) ? (
              <Link to={'/profile'}>
                <div className='lsp-business-alert'>
                  <p className='mb-0 '>
                    📍 Add location to your profile to access features
                    <img className='pl-2' src={blueArrow} alt='' />
                  </p>
                </div>
              </Link>
            ) : shouldShowLspPrompt(userData?.user, percentage) ? (
              <div className='lsp-alert-container'>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  {completedCount === 0 ? (
                    <img src={WarningAlert} alt='' />
                  ) : (
                    <VerificationPercentage percentage={percentage} />
                  )}

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <div className='ml-1'>
                      {completedCount > 0 ? (
                        <h4>
                          <strong>{percentage}% Complete</strong>
                        </h4>
                      ) : null}
                      <p className='mb-0 '>
                        Verify your account to access more features. Click
                        button to get started.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  onClick={handleSteps}
                  className='all-resources-btn'
                  style={{
                    cursor: 'pointer'
                  }}
                >
                  <p className='mb-0 mr-2'>{`${
                    isCompletedButUnverified ? 'Update' : 'Complete'
                  } Verification`}</p>
                  <img src={WhiteArrowIcon} alt='white arrow' />
                </div>
              </div>
            ) : null}

            {Boolean(shopnships && shopnships?.length) && (
              <Box className='pending-shopnships mb-3'>
                {shopnships.map(shopnship => (
                  <PendingShopnship
                    shopnship={shopnship}
                    onPay={() => handlePayShopnship(shopnship?.id || '')}
                  />
                ))}
              </Box>
            )}

            {businessId === 'null' ? (
              <DashboardCarousel
                shipmentCount={shipments?.data?.getShipments?.totalCount}
              />
            ) : null}

            <MonthlyShipmentCount />

            <div className='dashboard-cta-actions'>
              <div className='fund-wallet-cta'>
                <ReactTooltip
                  id='wallet-balance'
                  aria-haspopup='true'
                  role='example'
                >
                  <span className='tooltip-wrapper'>
                    Fund your wallet for a faster and more seamless experience!
                  </span>
                </ReactTooltip>
                <img
                  data-tip
                  data-for='wallet-balance'
                  src={InviteRed}
                  alt='gift card'
                  className='gift-image animate__animated animate__infinite animate__wobble'
                />
                <div className='fund-wallet-cta-contents'>
                  <div className='wallet-balance-items'>
                    <div className='wallet-balance-item'>
                      <img src={walletIcon} alt='wallet' />
                    </div>
                    <div
                      style={{ minWidth: '120px' }}
                      className='wallet-balance-item'
                    >
                      <h4 className='wallet-balance-item-heading'>
                        Your Balance
                      </h4>
                      <p className='wallet-balance-figure'>
                        {walletBalance.data &&
                          `₦${numberWithComma(
                            toFixed(
                              walletBalance.data.getWalletBalance.totalBalance /
                                100
                            )
                          )}`}
                      </p>
                    </div>
                  </div>
                  <div
                    className='fund-wallet-btn-link'
                    onClick={() => setOpenFundWalletModal(true)}
                  >
                    Fund Wallet
                  </div>
                </div>
              </div>
            </div>
            <section className='dashboard-cta-items'>
              <div
                className='dashboard-cta-item'
                onClick={() => setOpenBookShipmentModal(true)}
              >
                <div className='cta-item-contents'>
                  <div className='cta-icons'>
                    <img
                      className='cta-icon-image'
                      src={bookShipment}
                      alt='wallet'
                    />
                    <img src={blueArrow} alt='blue arrow' />
                  </div>
                  <div className='cta-texts'>
                    <h4 className='cta-item-heading'>Book Shipments</h4>

                    <p className='cta-item-text'>Send or receive an item</p>
                  </div>
                </div>
              </div>
              <div
                onClick={() => setIsChinaImport(false)}
                className='dashboard-cta-item'
              >
                <Link to={shopNShipLinks[1].link}>
                  <div className='cta-item-contents'>
                    <div className='cta-icons'>
                      <img
                        className='cta-icon-image'
                        src={shopNShip}
                        alt='shop and ship'
                      />
                      <img src={blueArrow} alt='blue arrow' />
                    </div>
                    <div className='cta-texts'>
                      <h4 className='cta-item-heading'>Shop {'&'} Ship</h4>

                      <p className='cta-item-text'>Mail packages to our hub</p>
                    </div>
                  </div>
                </Link>
              </div>
              <div className='dashboard-cta-item'>
                <Link
                  to={{
                    pathname: '/'
                  }}
                  onClick={() => {
                    ReactGA.event({
                      category: 'Dashboard Home Page',
                      action: 'User pressed the "Request Quotes" button'
                    });
                    setRequestQuotesPage(0);
                    setQuotesAddresses({
                      receiver: {},
                      sender: {}
                    });
                    setOpenGetQuotePrompt(true);
                  }}
                >
                  <div aria-label='Get Pricing' className='cta-item-contents'>
                    <div className='cta-icons'>
                      <img
                        className='cta-icon-image'
                        src={getQuote}
                        alt='wallet'
                      />
                      <img src={blueArrow} alt='blue arrow' />
                    </div>
                    <div className='cta-texts'>
                      <h4 className='cta-item-heading'>Get Pricing</h4>
                      <p className='cta-item-text'>Request a shipping quote</p>
                    </div>
                  </div>
                </Link>
              </div>
              <div className='dashboard-cta-item'>
                <Link
                  to={{
                    pathname: '/'
                  }}
                  onClick={() => {
                    ReactGA.event({
                      category: 'Dashboard Home Page',
                      action: 'User pressed the "Track Shipments" button'
                    });
                    setOpenTrackingModal(true);
                  }}
                >
                  <div className='cta-item-contents'>
                    <div className='cta-icons'>
                      <img
                        className='cta-icon-image'
                        src={trackShipment}
                        alt='wallet'
                      />
                      <img src={blueArrow} alt='blue arrow' />
                    </div>
                    <div className='cta-texts'>
                      <h4 className='cta-item-heading'>Track Shipment</h4>
                      <p className='cta-item-text'>Track your shipment</p>
                    </div>
                  </div>
                </Link>
              </div>
            </section>

            <section className='recent-shipments'>
              <div className='recent-shipments-header'>
                <h2>Recent Shipments</h2>
                {data?.getShipments?.list.length >= 1 && (
                  <Link to='/past-shipments'>
                    <p>
                      <span>View All</span>{' '}
                      <img src={viewAllArrow} alt='view all' />
                    </p>
                  </Link>
                )}
              </div>
              <div className='recent-shipment-items'>
                {loading
                  ? [...Array(take).keys()].map(key => (
                      <PastShipmentLoader key={key} loading={loading} />
                    ))
                  : data?.getShipments?.list?.slice(0, 5)?.map(shipment => (
                      <div
                        key={shipment?.id}
                        className='shipment-item'
                        onClick={() => {
                          setOpenShipmentModal(true);
                          setShipmentItem(shipment);
                        }}
                      >
                        <RecentShipment
                          shipment={shipment}
                          refetch={() => {
                            window.location.reload();
                          }}
                        />
                      </div>
                    ))}
              </div>
              {!data?.getShipments?.list.length && !loading ? (
                <EmptyList
                  style={{
                    width: '100%'
                  }}
                  emptyIcon={emptyStateIcon}
                  heading='You don’t have any Shipments yet'
                  subHeading='Request a new shipment and the log of all your shipments would appear here.'
                />
              ) : null}
            </section>
            <section
              className='recent-shipments'
              style={{
                marginTop: '50px'
              }}
            >
              <PendingTransactions take={5} shownOnDashboard />
            </section>
          </div>
        </Wrapper>
      </div>
    </>
  );
};

export default Dashboard;
