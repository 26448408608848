import React from 'react';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Close } from '@mui/icons-material';
import useClearLocalStorage from '../utilities/clear-localstorage';
import { useRecoilState } from 'recoil';
import { shopNShipResultState, warningState } from '../recoil/atoms';
import { toCurrency } from '../utilities/to-currency';
import { useUpdateShopnshipMutation } from '../operations/mutations';
import SubmitButton from './submit-button';
import { useSendAlert } from '../utilities/send-alert';
import Input from './new-dashboard/custom-input';
import DocumentUploadSkeleton from './document-upload-skeleton';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const PostBookingIdUpdate = ({
  setShowTrackingModal = () => {},
  setOpenBookingSuccessfulPrompt,
  setShowFeedbackModal = () => {},
  shipmentId = null
  // currentShipment
}) => {
  const [shopNShipDetails, setShopNShipResult] = useRecoilState(
    shopNShipResultState
  );

  const [, setShow] = useRecoilState(warningState);

  const [firstView, setFirstView] = useState();

  const [items, setItems] = useState([]);

  const sendAlert = useSendAlert();

  const clearStorage = useClearLocalStorage();

  const history = useHistory();

  const handleClose = () => {
    clearStorage(() => {
      // setShowTrackingModal(false);
      history.push('/');
    });
  };

  const [updateShopnship, { loading }] = useUpdateShopnshipMutation(data => {
    if (data) {
      sendAlert('Shipment Details Updated.');
      // clearStorage();
      localStorage.setItem('isShopNShip', true);

      setTimeout(() => {
        // history.push('/');
        if (!firstView) {
          setShowFeedbackModal(true);
        } else {
          setShowTrackingModal(false);
          setOpenBookingSuccessfulPrompt(true);
        }

        // if (!firstView) {
        //   history.push('/');
        // } else {
        //   setShowTrackingModal(false);
        // }
      }, 2000);
    }
  });

  const validationSchema = yup.object({
    items: yup.array().of(
      yup.object({
        trackingNumber: yup.string().required('Please enter tracking number')
      })
    )
  });

  const {
    errors,
    touched,
    values,
    handleSubmit,
    handleChange,
    handleBlur
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      items: items.map(item => ({
        ...item,
        trackingNumber: item.trackingNumber || ''
      }))
    },
    validationSchema,
    onSubmit: () => {
      const newItems = JSON.stringify(values.items);
      updateShopnship({
        id: shopNShipDetails.id,
        update: { itemDescription: newItems }
      });
    }
  });

  useEffect(() => {
    if (shopNShipDetails) {
      const parsedItems = JSON.parse(shopNShipDetails?.itemDescription) || '';
      setItems(parsedItems);
    }
    setFirstView(localStorage.getItem('justPaid'));
  }, []);

  const currentPage = 2;

  if (!items || items.length === 0) {
    return <DocumentUploadSkeleton />;
  }

  return (
    <div
      className={`tracking__id__modal-content ${
        firstView ? null : 'expand__to__full__width'
      }`}
      style={{ overflow: firstView ? 'scroll' : '' }}
    >
      {firstView ? (
        <div className='heading__text-container'>
          <p className='heading__text'>One Last Thing</p>
          <Close
            background='blue'
            onClick={handleClose}
            style={{ cursor: 'pointer' }}
          />
        </div>
      ) : (
        ''
      )}

      {firstView ? (
        <div className='progress__bar__container'>
          <div className='process__num__text'>
            <p className='progress__text'>Step {currentPage === 2 && '2'}</p>
            <p className='progress__text'> /2</p>
          </div>
          <div className='progess__bar__box'>
            <div
              className={`progress__bar__half ${
                !items || items.length === 0
                  ? ' '
                  : 'progress__bar__increase__full'
              }`}
            />
          </div>
        </div>
      ) : (
        ''
      )}
      <div className='heading__texts'>
        <p className='heading__sub__text-main'>Help us track your package</p>
        <p className='heading__sub__text-sub'>
          Enter your order tracking number below
        </p>
      </div>

      <form onSubmit={handleSubmit} className='tracking__modal__items'>
        {values.items.map((item, index) => (
          <div className='tracking__modal__item' key={item.id}>
            <p className='modal__item__heading'>Item {index + 1}</p>
            <div className='modal__item__boxes'>
              <div className='modal__item__box'>
                <div className='item__texts__box'>
                  <p className='item__box__label__text'>ITEM CATEGORY</p>
                  <p className='item__box__value__text'>{item.category}</p>
                </div>
                <div className='item__texts__box'>
                  <p className='item__box__label__text'>ITEM VALUE</p>
                  <p className='item__box__value__text'>
                    {toCurrency(item.amountDue)}
                  </p>
                </div>
              </div>
              <div className='modal__item__box'>
                <div className='item__texts__box'>
                  <p className='item__box__label__text'>QUANTITY</p>
                  <p className='item__box__value__text'>{item.quantity}</p>
                </div>
                <div className='item__texts__box'>
                  <p className='item__box__label__text'>WEIGHT</p>
                  <p className='item__box__value__text'>
                    {item.estimatedWeight} KG
                  </p>
                </div>
              </div>
            </div>
            <div action='' className='modal__item__form'>
              <label
                htmlFor={`items.${index}.trackingNumber`}
                className='item__form__label'
              >
                Order Tracking Number (from the retailer)
              </label>
              <Input
                name={`items.${index}.trackingNumber`}
                id={`items.${index}.trackingNumber`}
                value={values.items[index].trackingNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                type='text'
                placeholder='#000000000000'
                containerClassName='new-shop-n-ship__modal__body__form__item__input'
                fullWidth
              />
              {touched.items &&
                touched.items[index] &&
                errors.items &&
                errors.items[index] &&
                errors.items[index].trackingNumber && (
                  <div className='tracking__number__modal__error'>
                    {errors.items[index].trackingNumber}
                  </div>
                )}
            </div>
          </div>
        ))}
        <div className='modal__ctas'>
          <button
            className='modal__cta modal__cancel__cta'
            type='button'
            onClick={firstView ? handleClose : setShow}
          >
            {firstView ? 'Save & Upload Later' : 'Cancel'}
          </button>
          <SubmitButton
            loading={loading}
            className='modal__cta modal__complete__cta'
            type='submit'
            disabled={loading}
            text={'Complete Order'}
          />
        </div>
      </form>
    </div>
  );
};

export default PostBookingIdUpdate;
