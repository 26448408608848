import React, { useRef, useEffect, useState } from 'react';
import Slider from 'react-slick';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { IconButton } from '@mui/material';
import { fetchImageObject } from '../queries/fetch-images';

const DashboardCarousel = () => {
  const [imageList, setImageList] = useState([]);
  const settings = {
    dots: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  const nextRef = useRef();
  function NavigationArrow() {
    return (
      <div className='slider-arrow'>
        <IconButton
          style={{
            background: '#fff',
            borderRadius: '50%'
          }}
          className='arrow-btn prev'
          onClick={() => nextRef?.current?.slickPrev()}
        >
          <ArrowBackIosIcon />
        </IconButton>
        <IconButton
          style={{
            background: '#fff',
            borderRadius: '50%'
          }}
          className='arrow-btn next'
          onClick={() => nextRef?.current?.slickNext()}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </div>
    );
  }

  useEffect(() => {
    const result = fetchImageObject();
    result?.then(res => {
      setImageList(res?.data);
    });
  }, []);

  const imageUrl = 'https://topship-web.s3.eu-west-2.amazonaws.com/';

  // const bannerLinks = {
  //   1: 'https://blog.topship.africa/topship-app-on-shopify-from-installation-to-orders/'
  // };

  return (
    <div style={{ position: 'relative' }} className='banner-container'>
      {!!imageList.length && imageList.length > 1 && (
        <>
          <NavigationArrow />
          <Slider ref={nextRef} {...settings}>
            {imageList?.map((slide, slideIndex) => (
              <div
                key={slide.Key}
                className='banner-item'
                // onClick={() => {
                //   if (bannerLinks[slideIndex]) {
                //     window.open(bannerLinks[slideIndex], '_blank');
                //   }
                // }}
              >
                <img src={`${imageUrl}${slide?.Key}`} alt='ad banner' />
              </div>
            ))}
          </Slider>
        </>
      )}
      {!!imageList.length && imageList.length === 1 && (
        <>
          {imageList?.map((slide, slideIndex) => (
            <div
              key={slide.Key}
              className='banner-item p-0'
              style={{
                cursor: 'default'
              }}
            >
              <img src={`${imageUrl}${slide?.Key}`} alt='ad banner' />
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default DashboardCarousel;
