import React from 'react';
import { Skeleton } from '@mui/material';

const DocumentUploadSkeleton = () => {
  return (
    <div className='tracking__id__modal-content'>
      <div className='heading__text-container'>
        <Skeleton variant='text' width={200} height={32} />
        <Skeleton variant='circular' width={24} height={24} />
      </div>

      <div className='progress__bar__container'>
        <div className='process__num__text'>
          <Skeleton variant='text' width={80} height={24} />
        </div>
        <Skeleton variant='rectangular' width='100%' height={8} />
      </div>

      <div className='heading__texts'>
        <Skeleton variant='text' width='80%' height={28} />
        <Skeleton variant='text' width='60%' height={24} />
      </div>

      {[1, 2].map(item => (
        <div key={item} className='tracking__modal__item'>
          <Skeleton variant='text' width={100} height={24} />

          <div className='modal__item__boxes'>
            <div className='modal__item__box'>
              <div className='item__texts__box'>
                <Skeleton variant='text' width={120} height={20} />
                <Skeleton variant='text' width={100} height={24} />
              </div>
              <div className='item__texts__box'>
                <Skeleton variant='text' width={120} height={20} />
                <Skeleton variant='text' width={100} height={24} />
              </div>
            </div>
            <div className='modal__item__box'>
              <div className='item__texts__box'>
                <Skeleton variant='text' width={120} height={20} />
                <Skeleton variant='text' width={80} height={24} />
              </div>
              <div className='item__texts__box'>
                <Skeleton variant='text' width={120} height={20} />
                <Skeleton variant='text' width={100} height={24} />
              </div>
            </div>
          </div>

          <div style={{ marginTop: '20px' }}>
            <Skeleton variant='text' width={200} height={24} />
            <Skeleton variant='text' width={300} height={20} />
            <Skeleton
              variant='rectangular'
              width='100%'
              height={100}
              style={{ marginTop: '10px' }}
            />
          </div>

          <div style={{ marginTop: '20px' }}>
            <Skeleton variant='text' width={200} height={24} />
            <Skeleton variant='text' width={300} height={20} />
            <Skeleton
              variant='rectangular'
              width='100%'
              height={100}
              style={{ marginTop: '10px' }}
            />
          </div>

          <div style={{ marginTop: '20px' }}>
            <Skeleton variant='rectangular' width='100%' height={80} />
          </div>
        </div>
      ))}

      <div className='modal__ctas'>
        <Skeleton variant='rectangular' width={150} height={40} />
        <Skeleton variant='rectangular' width={150} height={40} />
      </div>
    </div>
  );
};

export default DocumentUploadSkeleton;
