import { useMutation, useApolloClient } from '@apollo/react-hooks';
import { useMutation as useReactQueryMutation } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import ReactGA from 'react-ga';
import {
  CREATE_USER,
  LOGIN_USER,
  SAVE_SHIPMENT,
  PAY_FROM_WALLET,
  SAVE_ADDRESS,
  DELETE_ADDRESS,
  FORGOT_PASSWORD,
  UPDATE_PASSWORD,
  SAVE_BANK_DETAILS,
  DELETE_BANK_DETAILS,
  INITIATE_WITHDRAWAL,
  CONFIRM_WITHDRAWAL_REQUEST,
  SAVE_FEEDBACK,
  SHOP_N_SHIP,
  UPDATE_ADDRESS,
  GET_CITIES_MUTATION,
  CANCEL_SHIPMENT,
  CHANGE_PASSWORD,
  UPDATE_SHOPNSHIP,
  CREATE_OR_UPDATE_LOGISTICS_BUSINESS_CONFIGS,
  UPDATE_USER,
  VERIFY_EMAIL,
  RESEND_OTP,
  UPDATE_BUSINESS_INFORMATION,
  CREATE_BUSINESS_INFORMATION,
  PAY_ADDITIONAL_CHARGE_BY_WALLET,
  UPDATE_SHIPMENT,
  UPLOAD_SHIPMENT_DOCUMENTS
} from './definitions/mutations.def';
import {
  GET_SAVED_ADDRESSES,
  GET_BANK_ACCOUNTS,
  GET_WALLET_BALANCE,
  GET_LOGISTICS_BUSINESS_CONFIGS,
  GET_TRANSACTIONS
} from './definitions/queries.def';
import { useSendAlert } from '../utilities/send-alert';
import { formatErrorMessage } from '../utilities/format-error';
import { parseGraphqlError } from '../utilities/parse-graphql-error';
import {
  loginState,
  showOtpState,
  feedbackOverlayState,
  quotesLocationDataState,
  multipleShipmentResultsState,
  multipleSaveShipmentLoadingState,
  shopNShipDetailsState,
  userUpdateLoadingState,
  saveShipmentDetailsState,
  shopNShipResultState
} from '../recoil/atoms';
import { useAuthToken } from '../token';
import Client from '../services/client';
import { shopNShipLinks } from '../constants/shopnship-links';
import { defaultChannel } from '../constants/channels';
import { useMemo } from 'react';
import { shipmentMethods } from '../constants/book-shipment-contants';
import testJSON from '../utilities/test-json';
import trackMetaEvent from '../utilities/useMetaEvent';

const useRegistrationMutation = () => {
  const sendAlert = useSendAlert();
  const [createUser, createUserResult] = useMutation(CREATE_USER, {
    onCompleted: data => {
      ReactGA.event({
        category: 'Sign ups',
        action: 'User has signed up'
      });

      trackMetaEvent('CompleteRegistration', {
        status: 'success'
      });
    },
    onError: error => {
      const isDuplicateKeyError = string =>
        new RegExp('duplicate key value violates unique constraint').test(
          string
        );
      const message = isDuplicateKeyError(parseGraphqlError(error))
        ? 'This phone number has been used by another user'
        : parseGraphqlError(error);
      sendAlert(message, 'error');
    }
  });

  const register = data => {
    createUser({
      variables: { newUser: { ...data, channel: defaultChannel } }
    });
  };

  return [register, createUserResult];
};

const useEmailVerificationMutation = onCompleted => {
  const sendAlert = useSendAlert();
  const [, setToken] = useAuthToken();
  const [, setLoggedIn] = useRecoilState(loginState);

  const [verify, verifyEmailResult] = useMutation(VERIFY_EMAIL, {
    onCompleted: data => {
      const {
        verifyEmail: { accessToken }
      } = data;

      setToken(process.env.REACT_APP_TOKEN_KEY, accessToken);
      setLoggedIn(true);

      ReactGA.event({
        category: 'Verification',
        action: 'User has verified his/her email successfully'
      });

      onCompleted && onCompleted(data);
    },
    onError: error => {
      sendAlert(parseGraphqlError(error), 'error');
    }
  });

  const verifyEmail = input => {
    verify({
      variables: { input }
    });
  };

  return [verifyEmail, verifyEmailResult];
};

const useResendOTPMutation = onCompleted => {
  const sendAlert = useSendAlert();
  const [resend, resendOTPResult] = useMutation(RESEND_OTP, {
    onCompleted: data => {
      onCompleted && onCompleted(data?.resendOTP);
      sendAlert(data?.resendOTP?.message, 'success');
    }
  });

  const resendOTP = id => {
    resend({ variables: { id } });
  };

  return [resendOTP, resendOTPResult];
};

const useLoginMutation = () => {
  const history = useHistory();
  const { search } = useLocation();
  const urlParams = useMemo(() => new URLSearchParams(search), [search]);
  const businessId = urlParams.get('lsp');
  const sendAlert = useSendAlert();
  const [, setToken, , getToken] = useAuthToken();
  const [, setLoggedIn] = useRecoilState(loginState);

  const [loginUser, loginResult] = useMutation(LOGIN_USER, {
    onCompleted: data => {
      const {
        login: { accessToken }
      } = data;
      setToken(process.env.REACT_APP_TOKEN_KEY, accessToken);
      setLoggedIn(true);
      const session = getToken('unauthorizedSession', true);

      if (session && session.length > 1) {
        history.push(session);
      }
      if (!!businessId) {
        urlParams.set('lsp', businessId);
      } else {
        urlParams.delete('lsp');
      }
      history.replace({
        search: urlParams?.toString()
      });
      window.location.reload();
      ReactGA.event({
        category: 'Authentication',
        action: 'User has logged in'
      });
    },
    onError: error => {
      sendAlert(parseGraphqlError(error), 'error');
    }
  });

  const login = data => {
    loginUser({
      variables: { existingUser: data }
    });
  };

  return [login, loginResult];
};

const useSaveShipmentMutation = onCompleted => {
  const sendAlert = useSendAlert();
  const [saveShipment, saveShipmentResult] = useMutation(SAVE_SHIPMENT, {
    onCompleted: data => {
      ReactGA.event({
        category: 'Shipment',
        action: 'User has saved shipment'
      });

      trackMetaEvent('CompleteShipment', {
        category: 'Shipment',
        status: 'success'
      });

      onCompleted?.(data);
    },
    onError: error => {
      sendAlert(parseGraphqlError(error), 'error');
      ReactGA.exception({
        description: `Error saving shipment (${parseGraphqlError(error)})`,
        fatal: true
      });

      trackMetaEvent('CompleteShipment', {
        category: 'Shipment',
        status: 'failed'
      });
    }
  });

  const saveShipmentDetails = (data, coupon = null) => {
    saveShipment({
      variables: {
        coupon: coupon,
        shipmentDetails: data.map(shipment => ({
          ...shipment,
          channel: defaultChannel
        }))
      }
    });
  };

  return [saveShipmentDetails, saveShipmentResult];
};

const useSaveMultipleShipmentsMutation = () => {
  const client = useApolloClient();
  const sendAlert = useSendAlert();
  const [shipmentDetails, setShipmentDetails] = useRecoilState(
    saveShipmentDetailsState
  );
  const [, setMultipleSaveShipmentLoading] = useRecoilState(
    multipleSaveShipmentLoadingState
  );

  const [, setMultipleShipmentResults] = useRecoilState(
    multipleShipmentResultsState
  );

  const saveShipmentDetails = async (shipmentData, coupon = null) => {
    const { data, error } = await client.mutate({
      mutation: SAVE_SHIPMENT,
      variables: {
        coupon,
        shipmentDetails: shipmentData.map(shipment => ({
          ...shipment,
          channel: defaultChannel
        }))
      }
    });

    if (error) {
      formatErrorMessage(error);
      sendAlert(parseGraphqlError(error), 'error');
      setMultipleSaveShipmentLoading({
        loading: false,
        completed: false
      });
      ReactGA.exception({
        description: `Error saving shipment (${parseGraphqlError(error)})`,
        fatal: true
      });
    }

    return data;
  };

  const saveMultipleShipments = async (shipments, bonusAmount) => {
    const responses = [];

    const shipmentInfo = {
      isError: false,
      index: undefined,
      pickupTrackingUrl: ''
    };

    setMultipleSaveShipmentLoading({ loading: true, completed: false });

    for await (const shipment of shipments) {
      const couponCode = shipment.couponCode;
      delete shipment.couponCode;

      const shipmentData = [
        {
          ...shipment,
          ...(bonusAmount > 0 && { discount: bonusAmount }),
          ...(shipmentInfo.pickupTrackingUrl && {
            pickupTrackingUrl: shipmentInfo.pickupTrackingUrl
          })
        }
      ];

      if (!shipmentInfo.isError) {
        const response = await saveShipmentDetails(shipmentData, couponCode)
          .then(data => {
            if (
              shipmentData[0]?.itemCollectionMode ===
                shipmentMethods[1].value &&
              !shipmentInfo.pickupTrackingUrl
            ) {
              const pickupTrackingUrlIndex = data?.saveShipment?.findIndex(
                shipment =>
                  !!shipment?.pickupTrackingUrl &&
                  testJSON(shipment?.pickupTrackingUrl)
              );

              if (pickupTrackingUrlIndex > -1) {
                shipmentInfo.pickupTrackingUrl =
                  data?.saveShipment?.[pickupTrackingUrlIndex]?.trackingId;
              }
            }

            return data;
          })
          .catch(error => {
            if (error) {
              shipmentInfo.isError = true;
              shipmentInfo.index = shipments?.findIndex(
                theShipment =>
                  JSON.stringify(theShipment) === JSON.stringify(shipment)
              );
              const message =
                !!error.message && error.message?.split('GraphQL error: ')[1];
              sendAlert(message || parseGraphqlError(error), 'error');
              setMultipleSaveShipmentLoading({
                loading: false,
                completed: false
              });
              setTimeout(() => {
                sendAlert(
                  'Please note that there is an issue with the information entered in one of your shipments, other shipments may be saved as draft, and you can pay for them later, on your dashboard.',
                  'warning'
                );
              }, 10000);
            }
          });
        responses.push(response);
      }
    }

    if (shipmentInfo.isError) {
      if (shipmentInfo.index && shipmentInfo.index > -1) {
        const remainingReceivers = shipmentDetails.multipleReceivers.filter(
          (_, receiverIndex) =>
            shipmentInfo.index === 0 || receiverIndex >= shipmentInfo.index
        );

        return setShipmentDetails(shipmentDetails => ({
          ...shipmentDetails,
          multipleReceivers: remainingReceivers
        }));
      }
    } else {
      const formattedResponse = responses.map((response, index) => {
        return { index, data: response.saveShipment };
      });

      setMultipleShipmentResults(formattedResponse);

      setMultipleSaveShipmentLoading({
        loading: false,
        completed: true
      });
    }
  };

  return saveMultipleShipments;
};

const useUpdateUserMutation = () => {
  const client = useApolloClient();
  const sendAlert = useSendAlert();
  const [, setUpdateLoading] = useRecoilState(userUpdateLoadingState);
  const updateUserMutation = async (update, topshipId) => {
    setUpdateLoading(true);
    try {
      const { data } = await client.mutate({
        mutation: UPDATE_USER,
        variables: {
          update,
          topshipId
        }
      });
      setUpdateLoading(false);
      sendAlert('Profile successfully updated', 'success');

      return data;
    } catch (error) {
      setUpdateLoading(false);

      sendAlert(parseGraphqlError(error), 'error');
    }
  };

  return updateUserMutation;
};

const usePayFromWalletMutation = () => {
  const sendAlert = useSendAlert();
  const [payFromWallet, payFromWalletResult] = useMutation(PAY_FROM_WALLET, {
    onCompleted: data => {},
    onError: error => {
      sendAlert(parseGraphqlError(error), 'error');
    },
    refetchQueries: [
      {
        query: GET_WALLET_BALANCE
      },
      'getWalletBalance'
    ]
  });

  const payFromWalletById = id => {
    payFromWallet({
      variables: {
        detail: {
          shipmentId: id
        }
      }
    });
  };

  return [payFromWalletById, payFromWalletResult];
};

const usePayAdditionalChargeByWalletMutation = onCompleted => {
  const sendAlert = useSendAlert();
  const [payAdditionalCharge, payAdditionalChargeResult] = useMutation(
    PAY_ADDITIONAL_CHARGE_BY_WALLET,
    {
      onCompleted: data => {
        sendAlert('Successful Transaction!');
        onCompleted && onCompleted(data);
      },
      onError: error => {
        sendAlert(parseGraphqlError(error), 'error');
      },
      refetchQueries: [
        {
          query: GET_WALLET_BALANCE
        },
        {
          query: GET_TRANSACTIONS
        },
        'getWalletBalance',
        'getTransactions'
      ]
    }
  );

  const payAdditionalChargeById = id => {
    payAdditionalCharge({
      variables: {
        transactionId: id
      }
    });
  };

  return [payAdditionalChargeById, payAdditionalChargeResult];
};

const useSaveAddressMutation = onCompleted => {
  const [saveAddress, saveAddressResults] = useMutation(SAVE_ADDRESS, {
    onCompleted: data => {
      const client = Client();
      client.resetStore();
      onCompleted && onCompleted();
    },
    refetchQueries: [
      {
        query: GET_SAVED_ADDRESSES
      },
      'getAddresses'
    ]
  });

  const saveCustomerAddress = address => {
    saveAddress({
      variables: { address }
    });
  };

  return [saveCustomerAddress, saveAddressResults];
};

const useDeleteAddressMutation = () => {
  const sendAlert = useSendAlert();
  const [deleteAddress, deleteAddressResults] = useMutation(DELETE_ADDRESS, {
    onCompleted: data => {
      sendAlert('Address deleted!');
      const client = Client();
      client.resetStore();
    },
    onError: error => {
      sendAlert(parseGraphqlError(error));
    },
    update: (cache, { data }) => {
      const allAddresses = cache.readQuery({
        query: GET_SAVED_ADDRESSES
      });
      const { list } = allAddresses.getAddresses;
      const updatedAddresses = list.filter(
        list => list.id !== data.deleteAddress.id
      );
      cache.writeQuery({
        query: GET_SAVED_ADDRESSES,
        data: { getAddresses: { list: updatedAddresses } }
      });
    },
    refetchQueries: [
      {
        query: GET_SAVED_ADDRESSES
      },
      'getAddresses'
    ]
  });

  const deleteCustomerAddress = id => {
    deleteAddress({
      variables: { id }
    });
  };

  return [deleteCustomerAddress, deleteAddressResults];
};

const useCancelShipmentMutation = onCompleted => {
  const sendAlert = useSendAlert();
  const [cancelShipment, cancelShipmentResults] = useMutation(CANCEL_SHIPMENT, {
    onCompleted: data => {
      onCompleted && onCompleted(data);
    },
    onError: error => {
      sendAlert(parseGraphqlError(error));
    }
  });

  const cancelUserShipment = id => {
    cancelShipment({
      variables: { id }
    });
  };

  return [cancelUserShipment, cancelShipmentResults];
};

const useForgotPasswordMutation = () => {
  const sendAlert = useSendAlert();
  const [resetPassword, resetPasswordResult] = useMutation(FORGOT_PASSWORD, {
    onCompleted: () => {},
    onError: error => {
      sendAlert(parseGraphqlError(error), 'alert');
    }
  });

  const resetUserPassword = userData => {
    resetPassword({
      variables: { userData }
    });
  };

  return [resetUserPassword, resetPasswordResult];
};

const useUpdatePasswordMutation = token => {
  const sendAlert = useSendAlert();
  const [updatePassword, updatePasswordResult] = useMutation(UPDATE_PASSWORD, {
    onCompleted: () => {},
    onError: error => {
      sendAlert(parseGraphqlError(error), 'alert');
    },
    context: {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  });

  const updateUserPassword = password => {
    updatePassword({
      variables: { newPassword: password }
    });
  };

  return [updateUserPassword, updatePasswordResult];
};

const useSaveBankDetailsMutation = () => {
  const sendAlert = useSendAlert();
  const client = Client();
  const [saveAccount, saveAccountResult] = useMutation(SAVE_BANK_DETAILS, {
    onCompleted: () => {
      client.resetStore();
    },
    onError: error => sendAlert(parseGraphqlError(error), 'error')
  });

  const saveBankDetails = bankDetail => {
    saveAccount({ variables: { bankDetail } });
  };

  return [saveBankDetails, saveAccountResult];
};

const useDeleteBankDetailMutation = () => {
  const sendAlert = useSendAlert();
  const [deleteBankDetail, deleteBankDetailResults] = useMutation(
    DELETE_BANK_DETAILS,
    {
      onCompleted: data => {
        sendAlert('Bank account deleted!');
      },
      onError: error => {
        sendAlert(parseGraphqlError(error));
      },
      update: (cache, { data }) => {
        const allBankDetails = cache.readQuery({
          query: GET_BANK_ACCOUNTS
        });

        const list = allBankDetails.getUserBankDetails;

        const updatedBankAccounts = list.filter(
          list => list.id !== data.deleteBankDetail.id
        );
        cache.writeQuery({
          query: GET_BANK_ACCOUNTS,
          data: { getUserBankDetails: updatedBankAccounts }
        });
      }
    }
  );

  const deleteBankAccount = id => {
    deleteBankDetail({
      variables: { id }
    });
  };

  return [deleteBankAccount, deleteBankDetailResults];
};

const useInitiateWithdrawalMutation = () => {
  const sendAlert = useSendAlert();
  const [, setShowOtp] = useRecoilState(showOtpState);
  const [initiateWithdrawal, initiateWithdrawalResult] = useMutation(
    INITIATE_WITHDRAWAL,
    {
      onError: error => sendAlert(parseGraphqlError(error), 'error'),
      onCompleted: () => setShowOtp(true)
    }
  );

  const initiateWithdrawalRequest = withdrawInput => {
    initiateWithdrawal({
      variables: { withdrawInput }
    });
  };
  return [initiateWithdrawalRequest, initiateWithdrawalResult];
};

const useConfirmWithdrawalRequestMutation = () => {
  const sendAlert = useSendAlert();
  const [, setShowOtp] = useRecoilState(showOtpState);
  const [
    confirmWithdrawalRequest,
    confirmWithdrawalRequestResult
  ] = useMutation(CONFIRM_WITHDRAWAL_REQUEST, {
    onError: error => sendAlert(parseGraphqlError(error), 'error'),
    onCompleted: () => setShowOtp(false)
  });

  const confirmWithdrawalRequestRequest = confirmationInput => {
    confirmWithdrawalRequest({
      variables: { confirmationInput }
    });
  };
  return [confirmWithdrawalRequestRequest, confirmWithdrawalRequestResult];
};

const useSaveFeedbackMutation = () => {
  const sendAlert = useSendAlert();
  const [, setShowFeedback] = useRecoilState(feedbackOverlayState);
  const [saveFeedback, saveFeedbackResults] = useMutation(SAVE_FEEDBACK, {
    onCompleted: data => {
      setShowFeedback(false);
    },
    onError: error => sendAlert(parseGraphqlError(error), 'error')
  });

  const saveCustomerFeedback = feedback => {
    saveFeedback({
      variables: { feedback }
    });
  };

  return [saveCustomerFeedback, saveFeedbackResults];
};

const useShopnShipMutation = onCompleted => {
  const history = useHistory();
  const sendAlert = useSendAlert();
  const [, setShopNShipResult] = useRecoilState(shopNShipResultState);
  const [shopnshipDetails] = useRecoilState(shopNShipDetailsState);
  const [saveShopnship, saveShopnshipResult] = useMutation(SHOP_N_SHIP, {
    onCompleted: data => {
      sendAlert('Item successfully submited!');
      ReactGA.event({
        category: 'Shop & Ship',
        action: 'User has submitted item(s) for Shop n Ship'
      });
      onCompleted && onCompleted(data);
    },
    onError: error => {
      const isInsuranceError = Boolean(
        error?.networkError?.result?.errors?.[0]?.message?.includes(
          'shopnship.insuranceType'
        )
      );
      if (isInsuranceError && !shopnshipDetails?.insuranceType) {
        sendAlert('Please select your Insurance Type', 'error');
        history.push(
          shopNShipLinks.find(link => link.link.includes('other-information'))
            .link
        );
      } else {
        sendAlert(parseGraphqlError(error), 'error');
      }
    }
  });

  const shopnship = variables => {
    saveShopnship({
      variables: {
        ...variables,
        shopnship: { ...(variables.shopnship || {}), channel: defaultChannel }
      }
    });
  };

  return [shopnship, saveShopnshipResult];
};

const useUpdateAddressMutation = onCompleted => {
  const sendAlert = useSendAlert();
  const [updateAddress, updateAddressResult] = useMutation(UPDATE_ADDRESS, {
    onCompleted: data => {
      onCompleted && onCompleted();
    },
    onError: error => {
      sendAlert(parseGraphqlError(error), 'error');
    },
    refetchQueries: [
      {
        query: GET_SAVED_ADDRESSES
      },
      'getAddresses'
    ]
  });
  const updateAddressInput = (update, id) => {
    updateAddress({
      variables: {
        update,
        id
      }
    });
  };
  return [updateAddressInput, updateAddressResult];
};

const useGetCitiesMutation = (onCompleted, locationType) => {
  const sendAlert = useSendAlert();
  const [quotesLocation, setQuotesLocation] = useRecoilState(
    quotesLocationDataState
  );
  const { mutate: getCities, isLoading, isError, data } = useReactQueryMutation(
    GET_CITIES_MUTATION,
    {
      onSuccess: data => {
        onCompleted && onCompleted(data?.data?.postalLocationList);
        if (locationType) {
          setQuotesLocation({
            ...quotesLocation,
            [locationType]: {
              ...quotesLocation[locationType],
              cities: data?.data?.postalLocationList
            }
          });
        }
      },
      onError: error => sendAlert(parseGraphqlError(error), 'error')
    }
  );
  const getCitiesMutationResult = {
    loading: isLoading,
    error: isError,
    data: data?.data?.postalLocationList || []
  };

  return [getCities, getCitiesMutationResult];
};

const useChangePasswordMutation = onCompleted => {
  const sendAlert = useSendAlert();
  const [changePasswordFunction, changePasswordResult] = useMutation(
    CHANGE_PASSWORD,
    {
      onCompleted: data => {
        sendAlert('Your password change was successful.');
        onCompleted && onCompleted(data);
      },
      onError: error => {
        const isPasswordError =
          parseGraphqlError(error).trim() === 'Bad Request Exception';

        const message = isPasswordError
          ? error?.graphQLErrors?.[0]?.extensions?.exception?.response
              ?.message?.[0]
          : parseGraphqlError(error);
        sendAlert(message, 'error');
      }
    }
  );

  const changePassword = variables => {
    changePasswordFunction({
      variables
    });
  };

  return [changePassword, changePasswordResult];
};

const useUpdateShopnshipMutation = onCompleted => {
  const sendAlert = useSendAlert();
  const [updateShopnshipFunction, updateShopnshipResult] = useMutation(
    UPDATE_SHOPNSHIP,
    {
      onCompleted: data => {
        onCompleted && onCompleted(data);
      },
      onError: error => sendAlert(parseGraphqlError(error), 'error')
    }
  );

  const updateShopnship = ({ update, id }) => {
    updateShopnshipFunction({
      variables: { update, id }
    });
  };

  return [updateShopnship, updateShopnshipResult];
};

const useCreateOrUpdateLogisticsBusinessConfigs = onCompleted => {
  const sendAlert = useSendAlert();

  const [
    createOrUpdateLogisticsBusinessConfig,
    mutateConfigResult
  ] = useMutation(CREATE_OR_UPDATE_LOGISTICS_BUSINESS_CONFIGS, {
    onCompleted: data => {
      onCompleted && onCompleted(data);
    },
    onError: error => {
      sendAlert(formatErrorMessage(error), 'error');
    },
    refetchQueries: [
      {
        query: GET_LOGISTICS_BUSINESS_CONFIGS
      },
      'getLogisticsBusinessConfigs'
    ]
  });

  const mutateConfig = configInput => {
    createOrUpdateLogisticsBusinessConfig({
      variables: {
        configInput
      }
    });
  };

  return [mutateConfig, mutateConfigResult];
};

const useUpdateBusinessMutation = onCompleted => {
  const sendAlert = useSendAlert();

  const [update, result] = useMutation(UPDATE_BUSINESS_INFORMATION, {
    onCompleted: data => {
      onCompleted && onCompleted(data);
    },
    onError: error => {
      sendAlert(parseGraphqlError(error), 'error');
    }
  });

  const updateBusiness = variables => {
    update({ variables });
  };

  return [updateBusiness, result];
};
const useCreateBusinessMutation = onCompleted => {
  const sendAlert = useSendAlert();

  const [input, result] = useMutation(CREATE_BUSINESS_INFORMATION, {
    onCompleted: data => {
      onCompleted && onCompleted(data);
    },
    onError: error => {
      sendAlert(parseGraphqlError(error), 'error');
    }
  });

  const createBusiness = variables => {
    input({ variables });
  };

  return [createBusiness, result];
};

const useUpdateShipmentMutation = onCompleted => {
  const sendAlert = useSendAlert();

  const [update, updateShipmentResult] = useMutation(UPDATE_SHIPMENT, {
    onCompleted: data => {
      if (onCompleted) {
        onCompleted(data);
      }
    },
    onError: error => {
      sendAlert(parseGraphqlError(error), 'error');
    }
  });

  const updateShipment = (id, input) => {
    update({
      variables: {
        id,
        update: input
      }
    });
  };

  return [updateShipment, updateShipmentResult];
};

const useUploadShipmentDocumentsMutation = onCompleted => {
  const sendAlert = useSendAlert();

  const [upload, uploadShipmentDocumentsResult] = useMutation(
    UPLOAD_SHIPMENT_DOCUMENTS,
    {
      onCompleted: data => {
        onCompleted?.(data);
      },
      onError: error => {
        sendAlert(parseGraphqlError(error), 'error');
      }
    }
  );

  const uploadDocs = async input => {
    return await upload({
      variables: {
        input
      }
    });
  };

  return [uploadDocs, uploadShipmentDocumentsResult];
};

export {
  useRegistrationMutation,
  useLoginMutation,
  useSaveShipmentMutation,
  usePayFromWalletMutation,
  useSaveAddressMutation,
  useDeleteAddressMutation,
  useForgotPasswordMutation,
  useUpdatePasswordMutation,
  useSaveBankDetailsMutation,
  useDeleteBankDetailMutation,
  useInitiateWithdrawalMutation,
  useConfirmWithdrawalRequestMutation,
  useSaveFeedbackMutation,
  useShopnShipMutation,
  useUpdateAddressMutation,
  useGetCitiesMutation,
  useCancelShipmentMutation,
  useSaveMultipleShipmentsMutation,
  useChangePasswordMutation,
  useUpdateShopnshipMutation,
  useCreateOrUpdateLogisticsBusinessConfigs,
  useUpdateUserMutation,
  useResendOTPMutation,
  useEmailVerificationMutation,
  useUpdateBusinessMutation,
  useCreateBusinessMutation,
  usePayAdditionalChargeByWalletMutation,
  useUpdateShipmentMutation,
  useUploadShipmentDocumentsMutation
};
