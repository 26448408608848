import { Close, Edit } from '@mui/icons-material';
import { Alert, Button, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import BookShipmentModal from '../../../../components/modals/book-shipment-modal';
import SubmitButton from '../../../../components/submit-button';
import { shipmentOptions } from '../../../../constants/book-shipment-contants';
import shipmentRateModeDictionary from '../../../../constants/shipment-rate-modes-dictionary';
import { useGetShipmentRateQuery } from '../../../../operations/queries';
import {
  budgetDeliveryState,
  saveShipmentDetailsState,
  saveShipmentRateState,
  selectedRateState
} from '../../../../recoil/atoms';
import { useSendAlert } from '../../../../utilities/send-alert';

import { InfoYellowIcon } from '../../../../constants/asset-contants';
import { toFixed } from '../../../../utilities/to-fixed';
import { DeliveryOptions1 } from '../../../../components/shipping-options';

const NewViewQuote = () => {
  const local = { country: 'Nigeria', code: 'ng' };
  const [openBookShipmentModal, setOpenBookShipmentModal] = useState(false);
  const [, setIsHeavyWeightPrompt] = useState(false);
  const [shipmentRates] = useRecoilState(saveShipmentRateState);
  const [shipmentDetail, setSaveShipmentDetail] = useRecoilState(
    saveShipmentDetailsState
  );
  const [selectedBudgetDelivery, setSelectedBudgetDelivery] = useRecoilState(
    budgetDeliveryState
  );

  const [selectedRate, setSelectedRate] = useRecoilState(selectedRateState);
  const history = useHistory();
  const [getShipmentRates, { loading }] = useGetShipmentRateQuery({
    transactionType: 'quote',
    handleRateSelection: rate => setSelectedRate(rate)
  });

  const quoteSummaryURL = window.location.href;
  const senderAddress = new URLSearchParams(window.location.search).get(
    'sender'
  );
  const receiverAddress = new URLSearchParams(window.location.search).get(
    'receiver'
  );
  const senderCountryCode = new URLSearchParams(window.location.search).get(
    'senderCountryCode'
  );
  const receiverCountryCode = new URLSearchParams(window.location.search).get(
    'receiverCountryCode'
  );
  const senderPostalCode = new URLSearchParams(window.location.search).get(
    'senderPostalCode'
  );
  const receiverPostalCode = new URLSearchParams(window.location.search).get(
    'receiverPostalCode'
  );
  const itemWeight = new URLSearchParams(window.location.search).get('weight');

  const sendAlert = useSendAlert();
  const senderDetails = {
    cityName: senderAddress?.split(',')[1] || '',
    countryCode: senderAddress?.split(',')[0] || '',
    postalCode: senderPostalCode
  };

  const receiverDetails = {
    cityName: receiverAddress?.split(',')[1] || '',
    countryCode: receiverAddress?.split(',')[0] || '',
    postalCode: receiverPostalCode
  };

  const totalWeight = parseFloat(itemWeight, 10);
  const businessId = localStorage.getItem('lspId');

  const getShipmentQuote = () => {
    getShipmentRates({
      senderDetails,
      receiverDetails,
      totalWeight,
      lsp: businessId !== 'null' ? businessId : null
    });
  };

  const handleEditShipmentQuote = () => {
    window.location.href = `/request-quote?${editQuoteDetailsURLParams}`;
  };

  useEffect(() => {
    getShipmentQuote();

    if (
      !senderAddress ||
      !receiverAddress ||
      !senderCountryCode ||
      !receiverCountryCode ||
      !itemWeight ||
      !senderPostalCode ||
      !receiverPostalCode
    ) {
      history.push('/request-quote');
    }

    if (totalWeight > 1000) {
      setIsHeavyWeightPrompt(true);
    }
    // eslint-disable-next-line
  }, []);

  const editQuoteDetailsURLParams = new URLSearchParams({
    senderCountryCode: senderCountryCode?.toLocaleUpperCase(),
    receiverCountryCode: receiverCountryCode?.toLocaleUpperCase(),
    senderAddress,
    receiverAddress,
    weight: String(parseFloat(itemWeight, 10)),
    senderPostalCode,
    receiverPostalCode
  }).toString();

  const handleBudgetDelivery = option => {
    setSelectedBudgetDelivery(option);
  };

  const handleSubmit = event => {
    event && event.preventDefault();
    const allItems = [
      {
        value: '',
        category: '',
        quantity: '',
        description: '',
        isCompleted: false,
        weight: totalWeight
      }
    ];

    const allReceivers = [
      {
        receiverDetail: {
          name: '',
          email: '',
          phoneNumber: '',
          addressLine1: '',
          addressLine2: '',
          addressLine3: '',
          country: '',
          state: '',
          postalCode: '',
          shouldSave: false,
          isCompleted: false,
          city: receiverDetails.cityName,
          countryCode: receiverCountryCode.toUpperCase()
        },
        items: allItems,
        couponCode: '',
        insuranceType: '',
        insuranceCharge: 0,
        isCompleted: false,
        shipmentCharge: toFixed(selectedRate?.cost),
        pricingTier: shipmentRateModeDictionary(selectedRate?.mode)
      }
    ];

    const isExport = senderCountryCode?.toLowerCase() === local.code;
    const isImport = receiverCountryCode?.toLowerCase() === local.code;

    setSaveShipmentDetail({
      ...shipmentDetail,
      senderDetail: {
        city: senderDetails.cityName,
        countryCode: senderCountryCode.toUpperCase(),
        country: isExport ? local.country : ''
      },
      receiverDetail: {
        city: receiverDetails.cityName,
        countryCode: receiverCountryCode.toUpperCase(),
        country: isImport ? local.country : ''
      },
      items: allItems,
      multipleReceivers: allReceivers,
      shipmentCharge: toFixed(selectedRate?.cost),
      pricingTier: shipmentRateModeDictionary(selectedRate?.mode),
      shipmentRoute: isImport
        ? shipmentOptions[1].value
        : shipmentOptions[0].value
    });
    setOpenBookShipmentModal(true);
  };

  const locationStyles = {
    display: 'flex',
    alignItems: 'center',
    gap: '10px'
  };

  const handleShare = async () => {
    try {
      if (window.navigator) {
        await window.navigator.share({ url: quoteSummaryURL });
      }
    } catch (error) {
      if (error.message === 'window.navigator.share is not a function') {
        window.open(
          `https://api.whatsapp.com/send?text=${encodeURIComponent(
            quoteSummaryURL
          )}`,
          '_blank'
        );
      } else {
        sendAlert("Your device can't share at this time", 'error');
      }
    }
  };

  return (
    <div className={`new-addresses__modal show`}>
      <BookShipmentModal
        openModal={openBookShipmentModal}
        setOpenModal={setOpenBookShipmentModal}
      />
      <div className='new-addresses__modal__wrap'>
        <div className='new-addresses__modal__header'>
          <div className='new-addresses__modal__header__wrap'>
            <h1>View Quote</h1>
            <IconButton onClick={() => history.push('/')}>
              <Close />
            </IconButton>
          </div>
        </div>
        <div className='new-addresses__modal__body'>
          <div className='new-addresses__modal__body__wrap qoute__body__wrap'>
            <div className='new-addresses__modal__body__label'>
              <p>Shipment Quote</p>
            </div>
            <div className='new-addresses__modal__body__content'>
              <form
                onSubmit={handleSubmit}
                className='new-addresses__modal__body__form'
              >
                <div className='new-addresses__modal__body__form__item'>
                  <label className='new-addresses__modal__body__form__item__label'>
                    Here’s the estimate of your shipment
                  </label>
                </div>
                <div className='order-summary__shipment'>
                  <div className='order-summary__shipment__wrap'>
                    <div className='order-summary__shipment__addresses'>
                      <div
                        className={`order-summary__shipment__address multiple`}
                      >
                        <div className='order-summary__shipment__address__wrap qoute__address__wrap'>
                          <div className='order-summary__shipment__address__content'>
                            <h4 className='order-summary__shipment__address__name'>
                              PICKUP FROM
                            </h4>
                            <span
                              className='order-summary__shipment__address__location'
                              style={locationStyles}
                            >
                              <img
                                loading='lazy'
                                width='20'
                                height='14'
                                src={`https://flagcdn.com/w20/${senderDetails.countryCode?.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${senderDetails.countryCode?.toLowerCase()}.png 2x`}
                                alt=''
                              />
                              {`${senderDetails.cityName} • ${senderDetails.countryCode}`}
                            </span>
                          </div>
                          <div className='order-summary__shipment__address__action'>
                            <Button
                              onClick={handleEditShipmentQuote}
                              variant='text'
                            >
                              <span>edit</span>
                              <Edit />
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className='order-summary__shipment__address deliver-to pb-0'>
                        <div className='order-summary__shipment__address__wrap'>
                          <div className='order-summary__shipment__address__content'>
                            <h4 className='order-summary__shipment__address__name'>
                              DELIVER TO
                            </h4>
                            <span
                              className='order-summary__shipment__address__location'
                              style={locationStyles}
                            >
                              <img
                                loading='lazy'
                                width='20'
                                height='14'
                                src={`https://flagcdn.com/w20/${receiverDetails.countryCode?.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${receiverDetails.countryCode?.toLowerCase()}.png 2x`}
                                alt=''
                              />
                              {`${receiverDetails.cityName} • ${receiverDetails.countryCode}`}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='order-summary__shipment__meta'>
                      <div className='order-summary__shipment__meta__wrap flex-row'>
                        <div className='order-summary__shipment__meta__content'>
                          <span className='order-summary__shipment__meta__content__label'>
                            Weight
                          </span>
                          <span className='order-summary__shipment__meta__content__value'>
                            {totalWeight} KG
                          </span>
                        </div>
                        <div
                          className='order-summary__shipment__meta__action'
                          style={{ width: '10rem' }}
                        >
                          <Button variant='text' onClick={handleShare}>
                            <span>Share</span>
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className='order-summary__shipment__meta pb-0'>
                      <div className='order-summary__shipment__meta__wrap'>
                        <div
                          className='order-summary__shipment__meta__content'
                          style={{ width: '100%' }}
                        >
                          <Alert
                            severity={'info'}
                            sx={{
                              marginTop: '',
                              fontSize: '1.6rem',
                              fontWeight: 'normal',
                              fontFamily: 'General Sans',
                              backgroundColor: 'rgba(239, 165, 22, 0.1)',
                              alignItems: 'flex-start',
                              color: '#0F1414',
                              border: 'none',
                              '& .MuiAlert-icon': {
                                fontSize: '2rem',
                                marginRight: '5px'
                              }
                            }}
                            icon={<img src={InfoYellowIcon} alt='info' />}
                          >
                            The price quoted is based on specified routes and
                            estimated weight. Price is exclusive of taxes,
                            pick-up fees, and customs clearance charges (where
                            applicable).
                          </Alert>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <label className='new-addresses__modal__body__form__item__label mt-3'>
                  Choose a Shipping Option
                </label>
                <DeliveryOptions1
                  showLabel={false}
                  loading={loading}
                  rates={shipmentRates}
                  selectedRate={selectedRate}
                  handleRateSelection={rate => setSelectedRate(rate)}
                  handleBudgetDelivery={handleBudgetDelivery}
                  selectedBudgetDelivery={selectedBudgetDelivery}
                  country={shipmentDetail?.receiverDetail?.country}
                  isQuotePage
                />

                <div className='delivery-options__footer mt-5 pb-5'>
                  <SubmitButton text='Ship Now' disabled={!selectedRate.mode} />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewViewQuote;
