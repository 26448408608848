import {
  shipmentMethods,
  shipmentOptions
} from '../constants/book-shipment-contants';

export const isItemCollectionModeMatch = (
  shipmentDetail,
  receiverIndex = -1
) => {
  const senderDetail = shipmentDetail?.senderDetail;

  const receiverDetail = shipmentDetail?.receiverDetail;

  if (
    senderDetail?.countryCode?.toLowerCase() === 'ng' &&
    receiverDetail?.countryCode?.toLowerCase() === 'ng' &&
    senderDetail?.state?.toLowerCase() === receiverDetail?.state?.toLowerCase()
  ) {
    return false;
  }

  if (receiverIndex > -1) {
    const receiver =
      shipmentDetail?.multipleReceivers?.[receiverIndex]?.receiverDetail;

    if (receiver) {
      if (
        senderDetail?.countryCode?.toLowerCase() === 'ng' &&
        receiver?.countryCode?.toLowerCase() === 'ng' &&
        senderDetail?.state?.toLowerCase() === receiver?.state?.toLowerCase()
      ) {
        return false;
      }
    }
  }

  return shipmentMethods[1]?.value === shipmentDetail?.itemCollectionMode;
};

export const getItemCategories = shipmentDetail => {
  return shipmentDetail?.items?.map(item => item.category);
};

export const getItemValues = shipmentDetail => {
  const totalValue = (shipmentDetail?.items || []).reduce(
    (accumulator, item) => accumulator + (item.value || 0),
    0
  );
  return totalValue;
};

export const isWithinLagos = shipmentDetail => {
  return shipmentDetail?.senderDetail?.state?.toLowerCase() === 'lagos';
};

export const getExpressOption = rates => {
  return rates.find(rate => rate?.pricingTier === 'Express');
};

export const isImportBooking = shipmentDetail => {
  return (
    shipmentDetail?.shipmentRoute === shipmentOptions[1].value &&
    shipmentDetail?.receiverDetail?.country?.toLowerCase() === 'nigeria' &&
    shipmentDetail?.receiverDetail?.state?.toLowerCase() !== 'lagos'
  );
};
