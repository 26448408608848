import { useState, useEffect } from 'react';

import PostBookingIdUpdate from '../post-booking-id-update';
import PostBookingDocUploads from '../post-booking-doc-uploads';

const TrackingIdModal = ({
  setShowTrackingModal,
  shipmentId,
  setOpenBookingSuccessfulPrompt,
  setOpenPaymentMethodPrompt
}) => {
  const [pageNum, setPageNum] = useState(1);

  useEffect(() => {
    setOpenPaymentMethodPrompt(false);
  }, []);

  return (
    <>
      <div className='tracking__id__modal show'>
        {pageNum === 1 ? (
          <PostBookingDocUploads
            setShowTrackingModal={setShowTrackingModal}
            shipmentId={shipmentId}
            setPageNum={setPageNum}
            setOpenBookingSuccessfulPrompt={setOpenBookingSuccessfulPrompt}
          />
        ) : (
          <PostBookingIdUpdate
            setShowTrackingModal={setShowTrackingModal}
            shipmentId={shipmentId}
            setOpenBookingSuccessfulPrompt={setOpenBookingSuccessfulPrompt}
          />
        )}
      </div>
    </>
  );
};

export default TrackingIdModal;
