import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import Maintenance from './pages/Maintenance/maintenance';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import client from './services/client';
import { RecoilRoot } from 'recoil';
import initMetaPixel from './utilities/loadMetaPixel';

initMetaPixel(process.env.REACT_APP_FACEBOOK_PIXEL_ID);

const maintenanceMode = false;

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client()}>
      <BrowserRouter>
        <RecoilRoot>{maintenanceMode ? <Maintenance /> : <App />}</RecoilRoot>
      </BrowserRouter>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
