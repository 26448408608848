import React, { useMemo, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Radio,
  Skeleton
} from '@mui/material';
import { toCurrency } from '../utilities/to-currency';
import { lightColors, shippingRates } from '../constants/shipping-rates';
import { extendedInsuranceLimits } from '../constants/insurance-plans';
import { toFixed } from '../utilities/to-fixed';
import { ExpandMore } from '@mui/icons-material';
import getPlan from '../utilities/getPlans';
import { negativeTags } from '../constants/negative-tags';
import {
  BlueCheck,
  CrossCheck,
  Estimator,
  InfoYellowIcon,
  InfoBlueIcon,
  InfoBrownIcon
} from '../constants/asset-contants';
import { BudgetPriceRatePrompt } from '../constants/budget-price-rates';
import { segregatedSaverCountries } from '../constants/china-categories';
import {
  getItemCategories,
  getItemValues,
  isWithinLagos
} from '../utilities/check-item-collection-mode';
import { getItemCategoryNotices } from '../constants/item-category-dictionary';
import clock from '../assets/svg/clock.svg';
import { useRecoilState } from 'recoil';
import {
  chinaImportState,
  shopnshipSelectedCountryState
} from '../recoil/atoms';
import { shipmentOptions } from '../constants/book-shipment-contants';
import EmptyList from './empty-list';
import emptyStateIcon from '../assets/svg/empty.svg';

const DeliveryOptions1 = ({
  loading,
  rates,
  selectedRate,
  handleRateSelection,
  showLabel = true,
  handleBudgetDelivery,
  selectedBudgetDelivery,
  country,
  isMultiple = false,
  activeIndex,
  openSaver,
  isQuotePage = false,
  shipmentDetail,
  multipleReceiver,
  isLastMile = false,
  openPickupOptionModal,
  setOpenPickupOptionModal
}) => {
  const [howItWorks, setHowItWorks] = useState(false);

  const checkIfSaver = rate => {
    return (
      rate.pricingTier === 'FedEx' &&
      selectedRate?.pricingTier?.toLowerCase() ===
        rate?.pricingTier?.toLowerCase()
    );
  };

  const estimatedValue = useMemo(() => {
    if (isMultiple) {
      return (getItemValues(multipleReceiver) || 0) / 100;
    }

    return (getItemValues(shipmentDetail) || 0) / 100;
  }, [isMultiple, multipleReceiver, shipmentDetail]);

  const thirtyPercentValue = useMemo(() => {
    if (!estimatedValue) {
      return 0;
    }

    return toFixed(estimatedValue * 0.3);
  }, [estimatedValue]);

  const fiftyPercentValue = useMemo(() => {
    if (!estimatedValue) {
      return 0;
    }

    return toFixed(estimatedValue * 0.5);
  }, [estimatedValue]);

  const isIntraCity = useMemo(() => {
    if (!shipmentDetail) {
      return false;
    }

    if (isMultiple) {
      const receiverDetail =
        shipmentDetail?.multipleReceivers?.[activeIndex]?.receiverDetail;

      const isDomestic =
        shipmentDetail?.senderDetail?.countryCode ===
        receiverDetail?.countryCode;

      return (
        isDomestic &&
        shipmentDetail?.senderDetail?.state === receiverDetail?.state
      );
    }

    const isDomestic =
      shipmentDetail?.senderDetail?.countryCode ===
      shipmentDetail?.receiverDetail?.countryCode;

    return (
      isDomestic &&
      shipmentDetail?.senderDetail?.state ===
        shipmentDetail?.receiverDetail?.state
    );
  }, [shipmentDetail, isMultiple, activeIndex]);

  const isExport =
    shipmentDetail?.shipmentRoute === 'Export' &&
    shipmentDetail?.receiverDetail?.country?.toLowerCase() !== 'nigeria';

  function extractFormattedText(contentArray) {
    return contentArray
      .map(item => {
        if (item.type === 'text') {
          return item.content;
        } else if (item.type === 'strong') {
          return `<strong>${extractFormattedText(item.content)}</strong>`;
        }
        return '';
      })
      .join('');
  }

  function convertToFormattedPTag(richTextArray) {
    return richTextArray
      .map(item => {
        const styleString = Object.entries(item.style || {})
          .map(([key, value]) => `${key}: ${value};`)
          .join(' ');

        return `<p style="${styleString}">${extractFormattedText(
          item.content
        )}</p>`;
      })
      .join('\n');
  }

  function processTags(tagsArray) {
    return tagsArray
      .map(tag => {
        try {
          const parsed = JSON.parse(tag);
          if (Array.isArray(parsed)) {
            return convertToFormattedPTag(parsed);
          }
        } catch (e) {
          return `<p>${tag}</p>`;
        }
      })
      .flat();
  }

  return (
    <div>
      {showLabel && (
        <label className='delivery-options__label mb-1'>
          Choose a Shipping Option
        </label>
      )}
      {!isQuotePage && isExport && !isLastMile && (
        <div>
          <p
            className='mb-3'
            style={{
              color: '#545859',
              fontSize: '14px',
              fontWeight: 500
            }}
          >
            All shipping rates include <b>Doorstep delivery</b> and{' '}
            <b>receiver's signature</b> would be required
          </p>

          <div className='mb-2'>
            <Alert
              severity={'info'}
              sx={{
                marginTop: '10px',
                fontSize: '1.5rem',
                fontWeight: 'normal',
                fontFamily: 'General Sans',
                border: '1px solid #EAC682',
                borderRadius: '8px',
                backgroundColor: 'rgba(239, 165, 22, 0.04)',
                alignItems: 'flex-start',
                color: '#0F1414',
                '& .MuiAlert-icon': {
                  fontSize: '2rem',
                  marginRight: '5px'
                }
              }}
              icon={<img src={InfoYellowIcon} alt='info' />}
            >
              <b>Please note:</b> The customs clearance fee below is charged
              withiin 30% - 50% of the declared item value. Ensure you correctly
              declare the value of the item being shipped. Under-declaration is
              considered fraud and may attract additional charges.
            </Alert>
          </div>
        </div>
      )}
      <div className='delivery-options__items'>
        {loading ? (
          [...Array(2).keys()].map(key => (
            <div key={key} className={`delivery-options__item`}>
              <div className='delivery-options__item__wrap discount'>
                <div className='delivery-options__item__content w-100'>
                  <div className='delivery-options__item__name w-100'>
                    <Skeleton className='w-100' />
                  </div>
                  <div className='delivery-options__item__address w-100'>
                    <Skeleton className='w-100' />
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : !!rates?.length ? (
          rates
            ?.filter(rate => {
              if (isIntraCity) {
                return rate?.pricingTier !== 'Express';
              }

              return true;
            })
            ?.map((rate, index) => {
              const mode = getPlan('shipping', rate?.pricingTier)?.name;
              const lastMileMode = getPlan('lastMile', rate?.pricingTier)?.name;
              const tags = processTags(rate?.tags);
              // const tags = getPlan('shipping', rate?.pricingTier)?.tags;
              const isSaver = rate?.mode?.includes('FedEx');
              const messages = getItemCategoryNotices(rate, shipmentDetail);

              const isSeaFreight = rate?.pricingTier === 'SeaExport';
              const seaFreightTags = getPlan('shipping', rate?.pricingTier)
                ?.tagsWithIcons;

              return (
                <React.Fragment key={index}>
                  <div
                    onClick={() => {
                      if (!isSaver) {
                        if (!rate?.isDisabled) {
                          if (
                            isSeaFreight &&
                            !isQuotePage &&
                            shipmentDetail?.receiverDetail?.countryCode?.toUpperCase() ===
                              'US'
                          ) {
                            setOpenPickupOptionModal(true);
                          }
                          if (rate?.pricingTier === 'FedEx') {
                            openSaver && openSaver();
                          }
                          if (isMultiple) {
                            handleRateSelection(rate, activeIndex);
                          } else {
                            handleRateSelection(rate);
                          }
                        }
                      }
                    }}
                    className={`new-get-quote__prompt__options__list__item${
                      selectedRate?.pricingTier === rate?.pricingTier
                        ? ' active'
                        : ''
                    }${rate?.isDisabled ? ' delivery-options__disabled' : ''}
                    ${!!rates?.id ? ' disabled' : ''}
                     ${
                       checkIfSaver(rate, selectedRate)
                         ? ' new-get-quote__prompt__options__list__item-alert alert-rate-border'
                         : ''
                     }
                    `}
                    style={{
                      background: lightColors[index % 5],
                      opacity: isSaver ? '0.4' : '1'
                    }}
                  >
                    <div
                      className={`mb-1 delivery-options__item__radio quote${
                        rate?.actualCost ? ' no-left-padding' : ''
                      }
                        ${
                          checkIfSaver(rate, selectedRate)
                            ? 'delivery-options__item__radio-alert'
                            : ''
                        }
                      `}
                    >
                      <Radio
                        checked={
                          selectedRate?.pricingTier === rate?.pricingTier
                        }
                        sx={{ transform: 'scale(1.5)' }}
                      />
                    </div>
                    <div
                      className='new-get-quote__prompt__options__list__item__wrap'
                      style={{
                        padding: '0 16px 0'
                      }}
                    >
                      <div
                        className='new-get-quote__prompt__options__list__item__content'
                        style={{
                          width: '100%'
                        }}
                      >
                        <div className='new-get-quote__prompt__options__list__item__name'>
                          <div
                            style={{
                              flexDirection: 'column',
                              alignItems: 'flex-start'
                            }}
                            className='mb-2 delivery-options__item__name-wrap'
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%'
                              }}
                            >
                              <div
                                style={{
                                  fontWeight: '700',
                                  paddingTop: '6px',
                                  color:
                                    selectedRate?.pricingTier ===
                                    rate?.pricingTier
                                      ? '#22428f'
                                      : '',
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '4px'
                                }}
                              >
                                {isLastMile && (
                                  <img
                                    src={rate?.logo}
                                    alt=''
                                    style={{
                                      width: '50px',
                                      height: '50px'
                                    }}
                                  />
                                )}
                                <h3
                                  style={{
                                    fontWeight: '700',
                                    paddingTop: '6px',
                                    color:
                                      selectedRate?.pricingTier ===
                                      rate?.pricingTier
                                        ? '#22428f'
                                        : ''
                                  }}
                                  className={`mb-0
                                   ${
                                     selectedRate?.pricingTier ===
                                     rate?.pricingTier
                                       ? ' active'
                                       : ''
                                   }
                                  `}
                                >
                                  {isLastMile
                                    ? `${lastMileMode}`
                                    : `${mode} ${
                                        mode?.includes('GIG') || isSeaFreight
                                          ? ''
                                          : 'SHIPPING'
                                      }`}
                                </h3>
                              </div>
                              <div className='new-get-quote__prompt__options__list__item__price'>
                                {rate?.actualCost && (
                                  <span className='no-discount'>
                                    {toCurrency(
                                      toFixed(rate?.actualCost / 100)
                                    )}
                                  </span>
                                )}
                                <span>{toCurrency(rate?.cost / 100)}</span>
                              </div>
                            </div>

                            {rate?.isRecommended && (
                              <div className='recommended ml-0 mt-1'>
                                Recommended
                              </div>
                            )}
                          </div>
                        </div>
                        <div className='new-get-quote__prompt__options__list__item__address'>
                          <span>{rate?.duration}</span>
                          <div
                            className='mt-2'
                            style={{
                              borderTop: '1px solid #EEF1F8'
                            }}
                          >
                            {/* {!isQuotePage && (
                              <div
                                className=''
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '6px',
                                  paddingTop: '10px'
                                }}
                              >
                                <img src={clock} alt='clock' />
                                <span
                                  style={{
                                    color: '#416EB2',
                                    fontSize: '12px',
                                    fontWeight: 500
                                  }}
                                >
                                  Processing time:{' '}
                                  <b>
                                    {rate?.pricingTier === 'Express' ? 24 : 48}
                                    hours {isExport}
                                  </b>
                                </span>
                              </div>
                            )} */}
                            {isSeaFreight ? (
                              <div>
                                <div
                                  style={{
                                    borderTop: '1px solid #EEF1F8',
                                    padding: '10px 0',
                                    marginTop: '10px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '10px'
                                  }}
                                >
                                  {seaFreightTags?.map(tag => {
                                    return (
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          gap: '6px'
                                        }}
                                      >
                                        <img src={tag.icon} alt='' />
                                        <span
                                          style={{
                                            color: '#416EB2',
                                            fontSize: '12px',
                                            fontWeight: 500
                                          }}
                                        >
                                          {tag.text}
                                        </span>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            ) : (
                              !isQuotePage &&
                              isExport &&
                              !isLastMile && (
                                <div
                                  className=''
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '6px',
                                    padding: '10px 0'
                                  }}
                                >
                                  <img src={Estimator} alt='' />
                                  <span
                                    style={{
                                      color: '#416EB2',
                                      fontSize: '12px',
                                      fontWeight: 500
                                    }}
                                  >
                                    Estimated Customs Clearance Fee:{' '}
                                    {rate?.pricingTier === 'LastMileBudget' ? (
                                      <b>{toCurrency(0)}</b>
                                    ) : (
                                      <b>
                                        {toCurrency(thirtyPercentValue, 'NGN')}{' '}
                                        - {toCurrency(fiftyPercentValue, 'NGN')}
                                      </b>
                                    )}
                                  </span>
                                </div>
                              )
                            )}
                          </div>
                          <div
                            className='flex'
                            style={{
                              paddingTop: '12px',
                              display: 'flex',
                              gap: '6px',
                              flexWrap: 'wrap',
                              flexDirection: 'column'
                            }}
                          >
                            {!isLastMile &&
                              tags &&
                              tags
                                .filter((_, index) => {
                                  if (
                                    rate?.pricingTier ===
                                      shippingRates[3].value &&
                                    shipmentDetail?.shipmentRoute ===
                                      shipmentOptions[1].value
                                  ) {
                                    return index < 3;
                                  }

                                  return true;
                                })
                                .map((tag, index) => {
                                  return (
                                    <div
                                      style={{
                                        background: negativeTags(tag)
                                          ? '#FFF8E6'
                                          : tag.includes(
                                              'Remote areas and outskirts:'
                                            )
                                          ? '#E6F2FF'
                                          : '#E1F9FF',
                                        color: negativeTags(tag)
                                          ? '#9E7201'
                                          : '#416EB2',
                                        borderRadius: '5px',
                                        display:
                                          !tag.includes(
                                            'Remote areas and outskirts:'
                                          ) &&
                                          !tag.includes(
                                            'Need same-day pick-up?'
                                          )
                                            ? 'flex'
                                            : selectedRate?.pricingTier ===
                                              rate?.pricingTier
                                            ? 'flex'
                                            : 'none',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        padding: '10px',
                                        width:
                                          tag.includes(
                                            'Need same-day pick-up?'
                                          ) ||
                                          tag.includes(
                                            'Remote areas and outskirts:'
                                          )
                                            ? '100%'
                                            : 'fit-content'
                                      }}
                                    >
                                      <img
                                        src={
                                          tag.includes(
                                            'Remote areas and outskirts:'
                                          )
                                            ? InfoBlueIcon
                                            : tag.includes(
                                                'Need same-day pick-up?'
                                              )
                                            ? InfoBrownIcon
                                            : negativeTags(tag)
                                            ? CrossCheck
                                            : BlueCheck
                                        }
                                        alt=''
                                      />
                                      {/* <p className='tag-text'>{tag}</p> */}
                                      <div
                                        className='tag-text'
                                        key={index}
                                        dangerouslySetInnerHTML={{
                                          __html: tag
                                        }}
                                      />
                                    </div>
                                  );
                                })}
                          </div>
                          {isSaver && (
                            <div
                              style={{
                                marginTop: '10px',
                                background: '#FFF8E6',
                                color: '#9E7201',
                                borderRadius: '5px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: '10px',
                                width: 'fit-content',
                                whiteSpace: 'break-spaces'
                              }}
                            >
                              <img src={CrossCheck} alt='' />
                              <p
                                style={{
                                  fontSize: '12px',
                                  paddingLeft: '8px',
                                  marginBottom: '0',
                                  fontWeight: 500
                                }}
                              >
                                Service is temporarily unavailable
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        padding: '0 16px 16px'
                      }}
                    >
                      {rate?.pricingTier === 'LastMileBudget' &&
                        isExport &&
                        !rate?.isDisabled && (
                          <BudgetPriceRatePrompt
                            howItWorks={howItWorks}
                            setHowItWorks={setHowItWorks}
                          />
                        )}
                      {!rate?.isDisabled && rate?.budgetDeliveryCost && (
                        <div className='mt-2'>
                          <Alert
                            severity={'info'}
                            sx={{
                              fontSize: '1.5rem',
                              fontFamily: 'General Sans',
                              backgroundColor: '#e6f2fe',
                              width: '100%',
                              color: '#22428F',
                              border: 'none',
                              '& .MuiAlert-icon': {
                                color: '#22428F'
                              }
                            }}
                          >
                            Note that budget shipments can only be processed
                            from our{' '}
                            <span
                              style={{
                                fontWeight: '500'
                              }}
                            >
                              Lagos hub
                            </span>
                            .
                          </Alert>
                          <div className='delivery-options__item__address delivery-options__item__budget-delivery mt-2 '>
                            <span className=''>
                              Select a Delivery Method to Lagos
                            </span>
                            <div
                              onClick={() => {
                                if (isMultiple) {
                                  handleBudgetDelivery(
                                    rate?.budgetDeliveryCost,
                                    activeIndex
                                  );
                                } else {
                                  handleBudgetDelivery(
                                    rate?.budgetDeliveryCost
                                  );
                                }
                              }}
                              className={`budget-option ${
                                selectedRate?.pricingTier?.toLowerCase() ===
                                  rate.pricingTier?.toLowerCase() &&
                                selectedBudgetDelivery ===
                                  rate?.budgetDeliveryCost
                                  ? '  active'
                                  : ''
                              }`}
                            >
                              <Radio
                                checked={
                                  selectedRate?.pricingTier?.toLowerCase() ===
                                    rate?.pricingTier?.toLowerCase() &&
                                  selectedBudgetDelivery ===
                                    rate?.budgetDeliveryCost
                                }
                                sx={{
                                  transform: 'scale(1.5)',
                                  padding: 0
                                }}
                              />
                              <div className='budget-price'>
                                <p
                                  className={` ${
                                    selectedRate?.pricingTier?.toLowerCase() ===
                                      rate?.pricingTier?.toLowerCase() &&
                                    selectedBudgetDelivery ===
                                      rate?.budgetDeliveryCost
                                      ? ' active'
                                      : ''
                                  }`}
                                >
                                  Ship to Lagos hub with express
                                </p>

                                <span className='price-tag'>
                                  {toCurrency(
                                    toFixed(rate?.budgetDeliveryCost / 100)
                                  )}
                                </span>
                              </div>
                            </div>
                            <div
                              onClick={() => handleBudgetDelivery(0)}
                              className={`budget-option ${
                                selectedRate?.pricingTier?.toLowerCase() ===
                                  rate?.pricingTier?.toLowerCase() &&
                                selectedBudgetDelivery === 0
                                  ? ' active'
                                  : ''
                              }`}
                            >
                              <Radio
                                checked={
                                  selectedRate?.pricingTier?.toLowerCase() ===
                                    rate?.pricingTier?.toLowerCase() &&
                                  selectedBudgetDelivery === 0
                                }
                                sx={{
                                  transform: 'scale(1.5)',
                                  padding: 0
                                }}
                              />
                              <div className='budget-price'>
                                <p
                                  className={`mb-0 ${
                                    selectedRate?.pricingTier?.toLowerCase() ===
                                      rate?.pricingTier?.toLowerCase() &&
                                    selectedBudgetDelivery === 0
                                      ? ' active'
                                      : ''
                                  }`}
                                >
                                  Ship to Lagos hub by yourself
                                </p>
                                <span className='price-tag'>FREE</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {rate?.remoteAreaCost &&
                        rate?.pricingTier === shippingRates[0].name && (
                          <Accordion
                            style={{
                              background: lightColors[index % 5]
                            }}
                            className='delivery-options__item__accordion'
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMore />}
                              aria-controls='panel1a-content'
                              id='panel1a-header'
                            >
                              <div className='delivery-options__item__name breakdown'>
                                <h3>Price Breakdown</h3>
                              </div>
                            </AccordionSummary>
                            <AccordionDetails className='delivery-options__item__accordion__details'>
                              <div className='delivery-options__item__accordion__element'>
                                <div className='delivery-options__item__accordion__element__wrap'>
                                  <div className='delivery-options__item__accordion__element__label'>
                                    ACTUAL COST
                                  </div>
                                  <div className='delivery-options__item__accordion__element__value'>
                                    {toCurrency(
                                      (rate?.cost - rate?.remoteAreaCost) / 100
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className='delivery-options__item__accordion__element'>
                                <div className='delivery-options__item__accordion__element__wrap'>
                                  <div className='delivery-options__item__accordion__element__label'>
                                    REMOTE DELIVERY COST
                                  </div>
                                  <div className='delivery-options__item__accordion__element__value'>
                                    {toCurrency(rate?.remoteAreaCost / 100)}
                                  </div>
                                </div>
                              </div>
                              <div className='delivery-options__item__accordion__element'>
                                <div className='delivery-options__item__accordion__element__wrap'>
                                  <div className='delivery-options__item__accordion__element__label'>
                                    TOTAL
                                  </div>
                                  <div className='delivery-options__item__accordion__element__value'>
                                    {toCurrency(toFixed(rate?.cost / 100))}
                                  </div>
                                </div>
                              </div>
                            </AccordionDetails>
                          </Accordion>
                        )}
                    </div>
                    {getItemCategories(shipmentDetail) &&
                    !getItemCategories(shipmentDetail)?.includes('Document') &&
                    (rate?.pricingTier === 'FedEx' ||
                      rate?.pricingTier === 'SaverPriority') ? (
                      <Alert
                        severity={'info'}
                        sx={{
                          fontSize: '1.4rem',
                          fontFamily: 'General Sans',
                          backgroundColor: '#FFF8E6',
                          width: '100%',
                          color: '#9E7201',
                          fontWeight: 500,
                          border: 'none',
                          '& .MuiAlert-icon': {
                            color: '#9E7201'
                          }
                        }}
                      >
                        Item must be shipped in a carton box. Please review item
                        weight as volumetric weight may apply.
                      </Alert>
                    ) : null}

                    {!isQuotePage &&
                      (rate?.pricingTier === 'FedEx' ||
                        rate?.pricingTier === 'SaverPriority') &&
                      !isWithinLagos(shipmentDetail) && (
                        <div>
                          <Alert
                            severity={'info'}
                            sx={{
                              fontSize: '1.5rem',
                              fontFamily: 'General Sans',
                              backgroundColor: '#e6f2fe',
                              width: '100%',
                              color: '#22428F',
                              border: 'none',
                              '& .MuiAlert-icon': {
                                color: '#22428F'
                              },
                              marginTop: '10px',
                              marginBottom: '10px'
                            }}
                          >
                            Delivery to Lagos may take 3 - 5 working days
                          </Alert>
                          <Alert
                            severity={'info'}
                            sx={{
                              fontSize: '1.5rem',
                              fontFamily: 'General Sans',
                              backgroundColor: '#e6f2fe',
                              marginBottom: '10px',
                              width: '100%',
                              color: '#22428F',
                              border: 'none',
                              '& .MuiAlert-icon': {
                                color: '#22428F'
                              }
                            }}
                          >
                            Live tracking may not function as expected, but rest
                            assured that your package is on its way to Lagos
                          </Alert>
                          {segregatedSaverCountries?.includes(country) &&
                            getItemCategories(shipmentDetail)?.includes(
                              'BeautyProducts'
                            ) && (
                              <Alert
                                severity={'info'}
                                sx={{
                                  fontSize: '1.5rem',
                                  fontFamily: 'General Sans',
                                  backgroundColor: '#e6f2fe',
                                  width: '100%',
                                  color: '#22428F',
                                  border: 'none',
                                  '& .MuiAlert-icon': {
                                    color: '#22428F'
                                  }
                                }}
                              >
                                Skincare items cannot be shipped to your
                                selected country on Saver, consider using the{' '}
                                <b>Express </b>
                                or <b>Budget</b> service for this shipment.
                              </Alert>
                            )}
                        </div>
                      )}

                    {rate?.pricingTier === 'FedEx' && (
                      <Alert
                        severity={'info'}
                        sx={{
                          fontSize: '1.5rem',
                          fontFamily: 'General Sans',
                          backgroundColor: 'rgba(240, 93, 61, 0.1)',
                          width: '100%',
                          color: '#D73512',
                          marginTop: '10px',
                          fontWeight: 500,
                          border: 'none',
                          '& .MuiAlert-icon': {
                            color: '#D73512'
                          }
                        }}
                      >
                        This service is experiencing significant processing
                        delays, your package(s) may not be delivered on time.
                        For urgent deliveries, please select a different service
                      </Alert>
                    )}

                    {Boolean(messages.length) && (
                      <Alert
                        severity={'info'}
                        className='item-description__main__alert'
                        sx={{
                          fontSize: '1.5rem',
                          fontFamily: 'General Sans',
                          // marginTop: '10px',
                          backgroundColor: '#e6f2fe',
                          color: '#22428F',
                          border: 'none',
                          '& .MuiAlert-icon': {
                            color: '#22428F'
                          }
                        }}
                      >
                        {messages}
                      </Alert>
                    )}
                  </div>
                </React.Fragment>
              );
            })
        ) : (
          <EmptyList
            emptyIcon={emptyStateIcon}
            heading='No Available Rates'
            subHeading='There are no rates available, please try again'
          />
        )}
        {/* <div
          onClick={() => {
            setOpenPickupOptionModal(true);

            if (isMultiple) {
              handleRateSelection(seaFreightExportRate, activeIndex);
            } else {
              handleRateSelection(seaFreightExportRate);
            }
          }}
          className={`new-get-quote__prompt__options__list__item${
            selectedRate?.pricingTier === seaFreightExportRate?.pricingTier
              ? ' active'
              : ''
          }`}
          style={{
            background: lightColors[0 % 5]
          }}
        >
          <div className={`mb-1 delivery-options__item__radio quote`}>
            <Radio
              checked={
                selectedRate?.pricingTier === seaFreightExportRate?.pricingTier
              }
              sx={{ transform: 'scale(1.5)' }}
            />
          </div>
          <div
            className='new-get-quote__prompt__options__list__item__wrap'
            style={{
              padding: '0 16px 0'
            }}
          >
            <div
              className='new-get-quote__prompt__options__list__item__content'
              style={{
                width: '100%'
              }}
            >
              <div className='new-get-quote__prompt__options__list__item__name'>
                <div
                  style={{
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                  }}
                  className='mb-2 delivery-options__item__name-wrap'
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%'
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px'
                      }}
                    >
                      <h3
                        style={{
                          fontWeight: '700',
                          paddingTop: '6px',
                          color:
                            selectedRate?.pricingTier ===
                            seaFreightExportRate?.pricingTier
                              ? '#22428f'
                              : ''
                        }}
                        className={`mb-0
                                   ${
                                     selectedRate?.pricingTier ===
                                     seaFreightExportRate?.pricingTier
                                       ? ' active'
                                       : ''
                                   }
                                  `}
                      >
                        {seaFreightExportRate?.pricingTier}
                      </h3>
                    </div>
                    <div className='new-get-quote__prompt__options__list__item__price'>
                      <span>{toCurrency(seaFreightExportRate?.amount)}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='new-get-quote__prompt__options__list__item__address'>
                <span>{seaFreightExportRate?.pickupDeliveryTime}</span>
                {!isQuotePage && isExport && (
                  <div
                    style={{
                      borderTop: '1px solid #EEF1F8',
                      padding: '10px 0',
                      marginTop: '10px',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '10px'
                    }}
                  >
                    {seaFreightExportRate?.tagsWithIcons.map(tag => {
                      return (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '6px'
                          }}
                        >
                          <img src={tag.icon} alt='' />
                          <span
                            style={{
                              color: '#416EB2',
                              fontSize: '12px',
                              fontWeight: 500
                            }}
                          >
                            {tag.text}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

const DeliveryOptions2 = ({
  loading,
  shopNShipRates,
  selectedRate,
  handleRateSelection,
  isPerKg = false,
  showLabel = false
}) => {
  const [selectedCountry] = useRecoilState(shopnshipSelectedCountryState);

  const [isChinaImport] = useRecoilState(chinaImportState);

  return (
    <div>
      {showLabel && (
        <label className='delivery-options__label mb-1'>
          Choose a Shipping Option
        </label>
      )}
      <div className='delivery-options__items'>
        {loading || !shopNShipRates?.length
          ? [...Array(2).keys()].map(key => (
              <div key={key} className={`delivery-options__item`}>
                <div className='delivery-options__item__wrap discount'>
                  <div className='delivery-options__item__content w-100'>
                    <div className='delivery-options__item__name w-100'>
                      <Skeleton className='w-100' />
                    </div>
                    <div className='delivery-options__item__address w-100'>
                      <Skeleton className='w-100' />
                    </div>
                  </div>
                </div>
              </div>
            ))
          : shopNShipRates?.map((rate, index) => {
              const tags = getPlan('shopnship', rate?.pricingTier)?.tags;

              return (
                <React.Fragment key={index}>
                  <div
                    onClick={() => {
                      handleRateSelection?.(rate);
                    }}
                    style={{
                      background: lightColors[index % 5]
                    }}
                    className={`delivery-options__item${
                      selectedRate?.pricingTier?.toLowerCase() ===
                      rate?.pricingTier?.toLowerCase()
                        ? ' active'
                        : ''
                    }
                  `}
                  >
                    <div className={`delivery-options__item__radio`}>
                      <Radio
                        checked={
                          selectedRate?.pricingTier?.toLowerCase() ===
                          rate?.pricingTier?.toLowerCase()
                        }
                        sx={{ transform: 'scale(1.5)' }}
                      />
                    </div>
                    <div
                      className='delivery-options__item__wrap'
                      style={{
                        display: 'block'
                      }}
                    >
                      <div className='delivery-options__item__content'>
                        <div
                          className='delivery-options__item__name mt-1'
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}
                        >
                          <div className=' delivery-options__item__name-wrap'>
                            <h3 style={{ fontWeight: '700' }} className=''>
                              {rate?.mode}
                            </h3>
                          </div>
                          <div className='delivery-options__item__price mt-0'>
                            <span>
                              {toCurrency(
                                rate?.foreignExchangeCost
                                  ? rate?.foreignExchangeCost
                                  : rate?.cost,
                                isChinaImport
                                  ? 'USD'
                                  : rate?.foreignExchangeCurrency ||
                                      `${
                                        selectedCountry?.value === 'UK'
                                          ? 'GBP'
                                          : 'USD'
                                      }`
                              )}

                              {isPerKg ? '/kg' : ''}
                            </span>
                          </div>
                        </div>
                        <div className='delivery-options__item__address'>
                          <span>{rate?.duration}</span>
                        </div>
                        <div
                          className='flex'
                          style={{
                            paddingTop: '12px',
                            display: 'flex',
                            gap: '6px',
                            flexWrap: 'wrap'
                          }}
                        >
                          {tags &&
                            tags.map(tag => {
                              return (
                                <div
                                  style={{
                                    background: negativeTags(tag)
                                      ? '#FFF8E6'
                                      : '#E1F9FF',
                                    color: negativeTags(tag)
                                      ? '#9E7201'
                                      : '#416EB2',
                                    borderRadius: '5px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: '10px',
                                    width: 'fit-content',
                                    whiteSpace: 'break-spaces'
                                  }}
                                >
                                  <img
                                    src={
                                      negativeTags(tag) ? CrossCheck : BlueCheck
                                    }
                                    alt=''
                                  />
                                  <p
                                    style={{
                                      fontSize: '12px',
                                      paddingLeft: '8px',
                                      marginBottom: '0',
                                      fontWeight: 500
                                    }}
                                  >
                                    {tag}
                                  </p>
                                </div>
                              );
                            })}
                        </div>
                        {/* {isCanada && (
                          <div
                            style={{
                              marginTop: '10px',
                              background: '#FFF8E6',
                              color: '#9E7201',
                              borderRadius: '5px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              padding: '10px',
                              width: 'fit-content',
                              whiteSpace: 'break-spaces'
                            }}
                          >
                            <img src={CrossCheck} alt='' />
                            <p
                              style={{
                                fontSize: '12px',
                                paddingLeft: '8px',
                                marginBottom: '0',
                                fontWeight: 500
                              }}
                            >
                              Service is temporarily unavailable
                            </p>
                          </div>
                        )} */}
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
      </div>
    </div>
  );
};

const InsuranceOptions = ({
  insurancePlans,
  totalItemValue,
  values,
  handleInsuranceSelection,
  shopNshipDetails,
  showLabel = false
}) => {
  return (
    <>
      {showLabel && (
        <label className='delivery-options__label mb-1'>
          Choose a Shipping Option
        </label>
      )}
      <div className='delivery-options__items'>
        {insurancePlans
          .filter(
            (_, index) =>
              totalItemValue >= extendedInsuranceLimits.min ||
              index < insurancePlans.length - 1
          )
          .map((plan, index) => (
            <div
              onClick={() =>
                !shopNshipDetails?.id && handleInsuranceSelection(plan)
              }
              style={{ background: lightColors[index % 5] }}
              key={plan.value}
              className={`delivery-options__item${
                values.insuranceType === plan.value ? ' active' : ''
              }${!!shopNshipDetails?.id ? ' disabled' : ''}`}
            >
              <div className={`delivery-options__item__radio`}>
                <Radio
                  checked={values.insuranceType === plan.value}
                  sx={{ transform: 'scale(1.5)' }}
                />
              </div>
              <div className='delivery-options__item__wrap'>
                <div className='delivery-options__item__content'>
                  <div className='delivery-options__item__name'>
                    <h3>{plan.name}</h3>
                  </div>
                  <div className='delivery-options__item__address'>
                    <span>{plan.summary}</span>
                  </div>
                </div>
                <div className='delivery-options__item__price'>
                  <span>
                    {plan?.price
                      ? toCurrency(plan?.price(totalItemValue))
                      : plan.amount
                      ? toCurrency(plan.amount)
                      : plan.cost}
                  </span>
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export { InsuranceOptions, DeliveryOptions1, DeliveryOptions2 };
