import * as React from 'react';
import {
  Autocomplete,
  Box,
  Button,
  TextField,
  InputAdornment
} from '@mui/material';
import { useStyles } from '../constants/custom-styles';
import { localAndExportsQuotesLocationDataState as quotesLocationDataState } from '../recoil/atoms';
import { useFormik } from 'formik';
import * as yup from 'yup';

import {
  useGetCountriesLazyQuery,
  useGetCitiesQuery,
  useGetShipmentRateQuery
} from '../operations/queries';
import { getDefaultValue } from '../utilities/get-default-value';
import Flag from '../assets/svg/FlagBanner.svg';
import Globe from '../assets/svg/GlobeSimple.svg';
import BusyOverlay from './busy-overlay';
import VolumetricWeight from './volumetric-weight-switch';
import StickyNoteAlert from './Commons/sticky-note';
import { roundUp } from '../utilities/round-up';
import trackMetaEvent from '../utilities/useMetaEvent';
const GetQuotesForm = () => {
  const classes = useStyles();
  const [locationType] = React.useState();
  const [getCountries, countries] = useGetCountriesLazyQuery();
  const countriesValue = countries?.data?.getCountries || [];
  const [
    getCities,
    { data: citiesResult, loading: citiesLoading }
  ] = useGetCitiesQuery(locationType, quotesLocationDataState);
  const citiesValue = citiesResult?.getCities || [];
  const [
    getRecipientCities,
    { data: citiesRecipientResult, loading: citiesRecipientLoading }
  ] = useGetCitiesQuery();
  const citiesRecipientValue = citiesRecipientResult?.getCities || [];

  const senderAddressEdit = new URLSearchParams(window.location.search).get(
    'senderAddress'
  );
  const receiverAddressEdit = new URLSearchParams(window.location.search).get(
    'receiverAddress'
  );

  const senderCountryCodeEdit = new URLSearchParams(window.location.search).get(
    'senderCountryCode'
  );
  const receiverCountryCodeEdit = new URLSearchParams(
    window.location.search
  ).get('receiverCountryCode');

  const senderPostalCodeEdit = new URLSearchParams(window.location.search).get(
    'senderPostalCode'
  );
  const receiverPostalCodeEdit = new URLSearchParams(
    window.location.search
  ).get('receiverPostalCode');

  const itemWeightEdit = new URLSearchParams(window.location.search).get(
    'weight'
  );
  const businessId = localStorage.getItem('lspId');

  React.useEffect(
    () => {
      getCountries();
      getCities('NG');
      if (receiverCountryCodeEdit) {
        getRecipientCities(receiverCountryCodeEdit);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getSenderCountryName = countriesValue?.filter(country => {
    return country?.code === senderCountryCodeEdit?.toLocaleUpperCase();
  });
  let senderCountryName = getSenderCountryName[0]?.name;
  const getReceiverCountryName = countriesValue?.filter(country => {
    return country?.code === receiverCountryCodeEdit?.toLocaleUpperCase();
  });
  let receiverCountryName = getReceiverCountryName[0]?.name;

  const senderDetails = {
    cityName: senderAddressEdit?.split(',')[1],
    countryCode: senderAddressEdit?.split(',')[0]
  };

  const receiverDetails = {
    cityName: receiverAddressEdit?.split(',')[1],
    countryCode: receiverAddressEdit?.split(',')[0]
  };

  const [
    getShipmentRates,
    { loading, data, error: shipmentError }
  ] = useGetShipmentRateQuery(data => {
    if (data) {
      trackMetaEvent('GetQuote', {
        status: 'success'
      });
    }
  });
  function calculateTotalWeight(values) {
    let totalWeightValue = 0;

    if (!!values.isVolumetric) {
      const volumetricWeight = Number(
        ((values.width * values.height * values.length) / 5000).toFixed(2)
      );
      totalWeightValue += volumetricWeight;
    } else {
      totalWeightValue += values.weight;
    }

    return totalWeightValue;
  }

  const {
    errors,
    touched,
    values,
    handleSubmit,
    handleChange,
    setFieldValue
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      isVolumetric: false,
      weight: itemWeightEdit || '',
      width: '',
      height: '',
      length: '',
      senderDetail: {
        country: senderCountryName || 'Nigeria',
        city: senderDetails.cityName || '',
        countryCode: senderCountryCodeEdit || 'NG',
        postalCode: senderPostalCodeEdit
      },
      receiverDetail: {
        country: receiverCountryName || '',
        city: receiverDetails.cityName || '',
        countryCode: receiverCountryCodeEdit || '',
        postalCode: receiverPostalCodeEdit
      }
    },

    validationSchema: yup.object().shape({
      isVolumetric: yup.bool(),
      weight: yup.number().when(['isVolumetric'], {
        is: isVolumetric => isVolumetric === false,
        then: yup
          .number()
          .min(0.09999, 'Please enter a weight greater than zero (0)')
          .required('Please enter the weight of your item(s)'),
        otherwise: yup.number().nullable().notRequired()
      }),
      width: yup.number().when(['isVolumetric'], {
        is: isVolumetric => isVolumetric === true,

        then: yup
          .number()
          .min(0.09999, 'Please enter a width greater than zero (0)')
          .required('Please enter the width of your box'),
        otherwise: yup.number().nullable().notRequired()
      }),
      height: yup.number().when(['isVolumetric'], {
        is: isVolumetric => isVolumetric === true,

        then: yup
          .number()
          .min(0.09999, 'Please enter a height greater than zero (0)')
          .required('Please enter the height of your box'),
        otherwise: yup.number().nullable().notRequired()
      }),
      length: yup.number().when(['isVolumetric'], {
        is: isVolumetric => isVolumetric === true,

        then: yup
          .number()
          .min(0.09999, 'Please enter a length greater than zero (0)')
          .required('Please enter the length of your box'),
        otherwise: yup.number().nullable().notRequired()
      }),
      senderDetail: yup.object().shape({
        country: yup.string().required(`Please enter pickup country`),
        city: yup.string().required(`Please enter pickup city`)
      }),
      receiverDetail: yup.object().shape({
        country: yup.string().required(`Please enter destination country`),
        city: yup.string().required(`Please enter destination city`)
      })
    }),

    onSubmit: values => {
      const totalWeight = calculateTotalWeight(values);
      const requestRatesData = {
        senderDetails: {
          cityName: values.senderDetail.city,
          countryCode: values.senderDetail.countryCode,
          postalCode: values?.senderDetail?.postalCode
        },
        receiverDetails: {
          cityName: values.receiverDetail.city,
          countryCode: values.receiverDetail.countryCode,
          postalCode: values?.receiverDetail?.postalCode
        },
        totalWeight: roundUp(totalWeight),
        lsp: businessId !== 'null' ? businessId : null
      };

      getShipmentRates(requestRatesData);
    }
  });

  const senderCountryCode = values.senderDetail.countryCode;
  const receiverCountryCode = values.receiverDetail.countryCode;

  const sender = `${values.senderDetail.countryCode},${values.senderDetail.city}`;
  const receiver = `${values.receiverDetail.countryCode},${values.receiverDetail.city}`;
  const totalWeight = calculateTotalWeight(values);

  const params = new URLSearchParams({
    senderCountryCode,
    receiverCountryCode,
    sender,
    receiver,
    senderPostalCode: values.senderDetail.postalCode,
    receiverPostalCode: values.receiverDetail.postalCode,
    weight: String(totalWeight)
  }).toString();

  const quoteSummaryURL = `/viewquote?${params}`;

  if (data && !shipmentError) {
    window.location.href = quoteSummaryURL;
  }

  const selectCountriesSelectionValues = (defaultValue, countryCode) => {
    if (countryCode === 'NG') {
      return defaultValue;
    }

    return [{ code: 'NG', name: 'Nigeria' }];
  };

  const loadingState = loading || citiesLoading || citiesRecipientLoading;

  return (
    <>
      <section className='form'>
        <div className='get-quotes-form'>
          <form onSubmit={handleSubmit} autoComplete='off'>
            <div className='quote-form-item quote-pickup'>
              <label htmlFor='pickup' className='quotes-label'>
                Pickup
              </label>
              <div className='sender-details'>
                <div className='column'>
                  <Autocomplete
                    id={`senderDetail.country`}
                    classes={{
                      input: classes.autoComplete,
                      option: classes.option
                    }}
                    options={selectCountriesSelectionValues(
                      countriesValue,
                      values.receiverDetail.countryCode
                    )}
                    getOptionLabel={option => option.name || ''}
                    isOptionEqualToValue={(option, value) => option === value}
                    noOptionsText='Country not found'
                    onChange={(e, country) => {
                      if (country) {
                        getCities(country.code);
                        selectCountriesSelectionValues(
                          countriesValue,
                          values.receiverDetail.countryCode
                        );
                        setFieldValue('senderDetail.countryCode', country.code);
                        setFieldValue(
                          `senderDetail.country`,
                          country?.name,
                          true
                        );
                      }
                    }}
                    value={
                      getDefaultValue(
                        countriesValue,
                        values.senderDetail.country,
                        'name'
                      ) || ''
                    }
                    renderOption={(props, option, country) => (
                      <Box
                        component='li'
                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                        {...props}
                        key={props.id}
                      >
                        {country && (
                          <img
                            loading='lazy'
                            width='30'
                            height='20'
                            src={`https://flagcdn.com/w20/${option.code?.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${option.code?.toLowerCase()}.png 2x`}
                            alt=''
                          />
                        )}
                        {option.name} ({option.code})
                      </Box>
                    )}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={'Select country'}
                        autoComplete='off'
                        inputProps={{
                          ...params.inputProps
                          // autoComplete: "off", // disable autocomplete and autofill
                        }}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <>
                              <InputAdornment position='start'>
                                <img
                                  loading='lazy'
                                  width='30'
                                  height='20'
                                  src={`https://flagcdn.com/w20/${values.senderDetail.countryCode.toLocaleLowerCase()}.png`}
                                  srcSet={`https://flagcdn.com/w40/${values.senderDetail.countryCode.toLocaleLowerCase()}.png 2x`}
                                  alt=''
                                />
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          ),
                          style: { fontSize: 14 }
                        }}
                        InputLabelProps={{ style: { fontSize: 14 } }}
                        value={values.senderDetail.country}
                        placeholder='Select sender country'
                        fullWidth
                      />
                    )}
                  />
                  {touched.senderDetail?.country &&
                    errors.senderDetail?.country && (
                      <p className='validation-error'>
                        {errors.senderDetail?.country}
                      </p>
                    )}
                </div>

                <div className='column'>
                  <Autocomplete
                    classes={{
                      input: classes.autoComplete,
                      option: classes.option
                    }}
                    id={`senderDetail.city`}
                    autoComplete={false}
                    options={citiesValue}
                    getOptionLabel={option => option.cityName || ''}
                    isOptionEqualToValue={(option, value) => option === value}
                    renderOption={(props, option) => (
                      <Box
                        component='li'
                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                        {...props}
                        key={props.id}
                      >
                        {option.cityName}
                      </Box>
                    )}
                    noOptionsText='City not found'
                    onChange={(e, city) => {
                      if (city) {
                        setFieldValue('senderDetail', {
                          ...values.senderDetail,
                          city: city?.cityName,
                          postalCode: city?.postcode
                        });
                      }
                    }}
                    value={
                      getDefaultValue(
                        citiesValue,
                        values.senderDetail.city,
                        'cityName'
                      ) || ''
                    }
                    renderInput={params => (
                      <TextField
                        {...params}
                        label='Select city'
                        autoComplete='off'
                        inputProps={{
                          ...params.inputProps
                          // autoComplete: "off", // disable autocomplete and autofill
                        }}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <>
                              <InputAdornment position='start'>
                                <img src={Flag} alt='flag' />
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          ),
                          style: { fontSize: 14 }
                        }}
                        InputLabelProps={{ style: { fontSize: 14 } }}
                        value={values.senderDetail.city}
                        placeholder='Select sender city'
                        fullWidth
                      />
                    )}
                  />
                  {touched.senderDetail?.city && errors.senderDetail?.city && (
                    <p className='validation-error'>
                      {errors.senderDetail?.city}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className='quote-form-item quote-destination'>
              <label htmlFor='destination' className='quotes-label'>
                Destination
              </label>
              <div className='receiver-details'>
                <div className='column'>
                  <Autocomplete
                    id={`receiverDetail.country`}
                    classes={{
                      input: classes.autoComplete,
                      option: classes.option
                    }}
                    options={selectCountriesSelectionValues(
                      countriesValue,
                      values.senderDetail.countryCode
                    )}
                    getOptionLabel={option => option.name || ''}
                    isOptionEqualToValue={(option, value) => option === value}
                    noOptionsText='Country not found'
                    onChange={(e, country) => {
                      if (country) {
                        getRecipientCities(country.code);
                        selectCountriesSelectionValues(
                          countriesValue,
                          values.senderDetail.countryCode
                        );
                        setFieldValue(
                          'receiverDetail.countryCode',
                          country.code
                        );
                        setFieldValue(
                          `receiverDetail.country`,
                          country?.name,
                          true
                        );
                      }
                    }}
                    value={
                      getDefaultValue(
                        countriesValue,
                        values.receiverDetail.country,
                        'name'
                      ) || ''
                    }
                    renderOption={(props, option) => (
                      <Box
                        component='li'
                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                        {...props}
                        key={props.id}
                      >
                        <img
                          loading='lazy'
                          width='30'
                          height='20'
                          src={
                            option.code
                              ? `https://flagcdn.com/w20/${option.code?.toLowerCase()}.png`
                              : Globe
                          }
                          srcSet={
                            option.code
                              ? `https://flagcdn.com/w40/${option.code?.toLowerCase()}.png 2x`
                              : Globe
                          }
                          alt=''
                        />
                        {option.name} ({option.code})
                      </Box>
                    )}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={'Select country'}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password' // disable autocomplete and autofill
                        }}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <>
                              <InputAdornment position='start'>
                                <img
                                  loading='lazy'
                                  width='30'
                                  height='20'
                                  src={
                                    values.receiverDetail.countryCode
                                      ? `https://flagcdn.com/w20/${values.receiverDetail.countryCode.toLocaleLowerCase()}.png`
                                      : Globe
                                  }
                                  srcSet={
                                    values.receiverDetail.countryCode
                                      ? `https://flagcdn.com/w40/${values.receiverDetail.countryCode.toLocaleLowerCase()}.png 2x`
                                      : Globe
                                  }
                                  alt=''
                                />
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          ),
                          style: { fontSize: 14 }
                        }}
                        InputLabelProps={{ style: { fontSize: 14 } }}
                        value={values.receiverDetail.country}
                        placeholder='Select receiver country'
                        fullWidth
                      />
                    )}
                    disabled={!citiesValue}
                  />
                  {touched.receiverDetail?.country &&
                    errors.receiverDetail?.country && (
                      <p className='validation-error'>
                        {errors.receiverDetail?.country}
                      </p>
                    )}
                </div>
                <div className='column'>
                  <Autocomplete
                    id={`receiverDetail.city`}
                    classes={{
                      input: classes.autoComplete,
                      option: classes.option
                    }}
                    options={citiesRecipientValue}
                    getOptionLabel={option => option.cityName || ''}
                    isOptionEqualToValue={(option, value) => option === value}
                    renderOption={(props, option) => (
                      <Box
                        component='li'
                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                        {...props}
                        key={props.id}
                      >
                        {option.cityName}
                      </Box>
                    )}
                    noOptionsText='City not found'
                    onChange={(e, city) => {
                      if (city) {
                        setFieldValue('receiverDetail', {
                          ...values.receiverDetail,
                          city: city?.cityName,
                          postalCode: city?.postcode
                        });
                      }
                    }}
                    value={
                      getDefaultValue(
                        citiesRecipientValue,
                        values.receiverDetail.city,
                        'cityName'
                      ) || ''
                    }
                    renderInput={params => (
                      <TextField
                        {...params}
                        label='Select city'
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password' // disable autocomplete and autofill
                        }}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <>
                              <InputAdornment position='start'>
                                <img src={Flag} alt='flag' />
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          ),
                          style: { fontSize: 14 }
                        }}
                        InputLabelProps={{ style: { fontSize: 14 } }}
                        value={values.receiverDetail?.city}
                        placeholder='Select receiver city'
                        fullWidth
                      />
                    )}
                  />
                  {/* <Autocomplete
                    classes={{
                      input: classes.autoComplete,
                      option: classes.option
                    }}
                    id={`receiverDetail.city`}
                    autoComplete={false}
                    options={citiesValue}
                    getOptionLabel={option => option.cityName || ''}
                    isOptionEqualToValue={(option, value) => option === value}
                    renderOption={(props, option) => (
                      <Box
                        component='li'
                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                        {...props}
                        key={props.id}
                      >
                        {option.cityName}
                      </Box>
                    )}
                    noOptionsText='City not found'
                    onChange={(e, city) => {
                      if (city) {
                        setFieldValue(
                          `receiverDetail.city`,
                          city?.cityName,
                          true
                        );
                      }
                    }}
                    value={
                      getDefaultValue(
                        citiesValue,
                        values.receiverDetail.city,
                        'cityName'
                      ) || ''
                    }
                    renderInput={params => (
                      <TextField
                        {...params}
                        label='Select city'
                        autoComplete='off'
                        inputProps={{
                          ...params.inputProps
                          // autoComplete: "off", // disable autocomplete and autofill
                        }}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <>
                              <InputAdornment position='start'>
                                <img src={Flag} alt='flag' />
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          ),
                          style: { fontSize: 14 }
                        }}
                        InputLabelProps={{ style: { fontSize: 14 } }}
                        value={values.receiverDetail.city}
                        placeholder='Select receiver city'
                        fullWidth
                      />
                    )}
                  /> */}
                  {touched.receiverDetail?.city &&
                    errors.receiverDetail?.city && (
                      <p className='validation-error'>
                        {errors.receiverDetail?.city}
                      </p>
                    )}
                </div>
              </div>
            </div>
            <div className=''>
              <VolumetricWeight
                checkBoxName={`isVolumetric`}
                isActive={values.isVolumetric}
                handleToggleSwitch={() =>
                  setFieldValue(`isVolumetric`, !values.isVolumetric)
                }
              />
            </div>
            <div
              style={{
                marginTop: '30px'
              }}
            >
              {values.isVolumetric ? (
                <div>
                  <div className='quote-form-item quote-weight'>
                    <label htmlFor='width' className='quotes-label'>
                      Width (CM)
                    </label>
                    <div className='quote-weight-details'>
                      <TextField
                        className={classes.input}
                        id='width'
                        label='Width (CM)'
                        type='number'
                        step='1.00'
                        variant='outlined'
                        InputProps={{
                          InputProps: { type: 'number', min: 0, max: 10 },
                          style: { fontSize: 16 }
                        }}
                        InputLabelProps={{
                          style: { fontSize: 12 }
                        }}
                        onChange={handleChange}
                        value={values.width}
                        placeholder='Add box width'
                        fullWidth
                      />
                      {touched.width && errors.width && (
                        <p className='validation-error'>{errors.width}</p>
                      )}
                    </div>
                  </div>
                  <div className='quote-form-item quote-weight'>
                    <label htmlFor='height' className='quotes-label'>
                      Height (CM)
                    </label>
                    <div className='quote-weight-details'>
                      <TextField
                        className={classes.input}
                        id='height'
                        label='Height (CM)'
                        type='number'
                        step='1.00'
                        variant='outlined'
                        InputProps={{
                          InputProps: { type: 'number', min: 0, max: 10 },
                          style: { fontSize: 16 }
                        }}
                        InputLabelProps={{
                          style: { fontSize: 12 }
                        }}
                        onChange={handleChange}
                        value={values.height}
                        placeholder='Add box height'
                        fullWidth
                      />
                      {touched.height && errors.height && (
                        <p className='validation-error'>{errors.height}</p>
                      )}
                    </div>
                  </div>{' '}
                  <div className='quote-form-item quote-weight'>
                    <label htmlFor='length' className='quotes-label'>
                      Length (CM)
                    </label>
                    <div className='quote-weight-details'>
                      <TextField
                        className={classes.input}
                        id='length'
                        label='Length (CM)'
                        type='number'
                        step='1.00'
                        variant='outlined'
                        InputProps={{
                          InputProps: { type: 'number', min: 0, max: 10 },
                          style: { fontSize: 16 }
                        }}
                        InputLabelProps={{
                          style: { fontSize: 12 }
                        }}
                        onChange={handleChange}
                        value={values.length}
                        placeholder='Add box length'
                        fullWidth
                      />
                      {touched.length && errors.length && (
                        <p className='validation-error'>{errors.length}</p>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className='quote-form-item quote-weight'>
                  <label htmlFor='weight' className='quotes-label'>
                    Item Weight (kg)
                  </label>
                  <div className='quote-weight-details'>
                    <TextField
                      className={classes.input}
                      id='weight'
                      label='Item Weight (kg)'
                      type='number'
                      step='1.00'
                      variant='outlined'
                      InputProps={{
                        InputProps: { type: 'number' },
                        style: { fontSize: 16 }
                      }}
                      InputLabelProps={{
                        style: { fontSize: 12 }
                      }}
                      onChange={handleChange}
                      value={values.weight}
                      placeholder='Add item weight'
                      fullWidth
                    />
                    {touched.weight && errors.weight && (
                      <p className='validation-error'>{errors.weight}</p>
                    )}
                  </div>
                </div>
              )}
            </div>

            <div className='mt-2  mb-3'>
              <StickyNoteAlert isSmallerScreen={true} isVolumetric={true} />
            </div>
            <div
              style={{
                paddingBottom: '40px'
              }}
            >
              <Button type='submit' className='get-quote-submit-button'>
                Request a Quote
              </Button>
            </div>
          </form>
        </div>
      </section>
      <BusyOverlay loading={loadingState} />
    </>
  );
};

export default GetQuotesForm;
