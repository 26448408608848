import React from 'react';
import PostBookingDocUploads from './post-booking-doc-uploads';
import ShopNShipUpdateLayout from './shop-n-ship-update-layout';

const ShopNShipDocumentUpload = () => {
  return (
    <>
      <ShopNShipUpdateLayout mobileTitle='Upload Documents'>
        <PostBookingDocUploads />
      </ShopNShipUpdateLayout>
    </>
  );
};

export default ShopNShipDocumentUpload;
