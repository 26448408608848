import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { shipmentPaymentState, shopNShipResultState } from '../../recoil/atoms';
import { Link } from 'react-router-dom';
import paymentSuccessIcon from '../../assets/svg/payment-successful.svg';
import SubmitButton from '../submit-button';
import { Helmet } from 'react-helmet';
import useClearLocalStorage from '../../utilities/clear-localstorage';

const PaymentSuccessfull = ({
  heading,
  subHeading,
  path,
  buttonText,
  successImage
}) => {
  const [, setRequestPage] = useRecoilState(shipmentPaymentState);
  const [isShopnship] = useState(localStorage.getItem('isShopNShip'));

  const clearStorage = useClearLocalStorage();

  const handleComplete = () => {
    clearStorage();
    setRequestPage(0);
  };

  return (
    <div className='payment-successful'>
      <Helmet>
        <meta name='description' content='Topship Africa Shipment Payment' />
        <title>Topship Africa Shipment Payment Successful</title>
        <link
          rel='canonical'
          href='https://topship.africa/shipment-payment-successful'
        />
      </Helmet>
      <img src={successImage || paymentSuccessIcon} alt='success' />

      {isShopnship ? (
        <h1>{heading || 'All Done!'}</h1>
      ) : (
        <h1>{heading || 'Payment Successful'}</h1>
      )}
      {isShopnship ? (
        <p className='mt-1'>{subHeading || 'Thank you for your feedback.'}</p>
      ) : (
        <p className='mt-1'>
          {subHeading ||
            'A receipt has been sent to your email containing the order information for your shipment.'}
        </p>
      )}

      <div className='mt-6 mb-3'>
        <Link to={path || '/'}>
          <SubmitButton
            text={buttonText || 'Go Home'}
            className='mt-3'
            onClick={() => handleComplete()}
          />
        </Link>
      </div>
    </div>
  );
};

export default PaymentSuccessfull;
