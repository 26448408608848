import React, { useState } from 'react';
import PostBookingIdUpdate from './post-booking-id-update';
import ShopNShipUpdateLayout from './shop-n-ship-update-layout';
import BookingSuccessfulPrompt from './modals/booking-successful-prompt';

const ShopNShipOrderNumtUpdate = () => {
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);

  return (
    <>
      <BookingSuccessfulPrompt
        openPrompt={showFeedbackModal}
        setOpenPrompt={setShowFeedbackModal}
      />
      <ShopNShipUpdateLayout mobileTitle='Upload Documents'>
        <PostBookingIdUpdate setShowFeedbackModal={setShowFeedbackModal} />
      </ShopNShipUpdateLayout>
    </>
  );
};

export default ShopNShipOrderNumtUpdate;
