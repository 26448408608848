import React, { useEffect } from 'react';
import { IconButton } from '@mui/material';
import { Check, Close } from '@mui/icons-material';
import { useHistory, useLocation } from 'react-router-dom';
import DeleteOverlay from '../components/delete-overlay';
import { useRecoilState } from 'recoil';
import {
  filteredShopNShipLinksState,
  shopNShipDetailsState,
  shopNShipValidationState,
  warningState
} from '../recoil/atoms';
import { LinearProgress } from '@material-ui/core';
import { shopNShipUpdateLinks } from '../constants/shopnship-links';

const ShopNShipUpdateLayout = ({ children, mobileTitle }) => {
  const responsive = { smaller: 1000 };
  const history = useHistory();
  const { pathname } = useLocation();
  const [, setShow] = useRecoilState(warningState);
  const [shopNShipValidation, setShopNShipValidation] = useRecoilState(
    shopNShipValidationState
  );
  const [shopNshipDetails] = useRecoilState(shopNShipDetailsState);
  const [filteredLinks, setFilteredLinks] = useRecoilState(
    filteredShopNShipLinksState
  );

  useEffect(() => {
    setFilteredLinks(shopNShipUpdateLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopNShipUpdateLinks]);

  const handleClose = () => {
    localStorage.clear();
    history.push('/');
  };

  const pushRoute = index => {
    if (!shopNshipDetails.id) {
      if (filteredLinks.findIndex(link => link.link === pathname) < index) {
        if (shopNShipValidation.isValidated) {
          history.push(filteredLinks[index].link);
        } else {
          setShopNShipValidation({
            ...shopNShipValidation,
            enforceValidation: true
          });
        }
      } else {
        history.push(filteredLinks[index].link);
      }
    }
  };
  const screenSize = window.innerWidth;

  return (
    <div className={`new-shipping-steps-layout`}>
      <DeleteOverlay
        warningMessage={'You might have unsaved changes'}
        deleteText={'Close'}
        onDelete={handleClose}
      />
      <div className='new-shipping-steps-layout__wrap'>
        <div className='new-shipping-steps-layout__header'>
          <div className='new-shipping-steps-layout__header__wrap'>
            <h1>Shop & Ship</h1>
            <IconButton onClick={() => setShow(true)}>
              <Close
                style={{
                  fontSize: screenSize < 500 ? '14px' : ''
                }}
              />
            </IconButton>
          </div>
        </div>
        <div className='new-shipping-steps-layout__body'>
          <div className='new-shipping-steps-layout__body__wrap update__steps__body__wrap'>
            <div className='new-shipping-steps-layout__body__label update__steps__label'>
              {filteredLinks.map((link, index) => (
                <span
                  key={link.name}
                  onClick={() =>
                    pathname === '/shop-and-ship-delivery-address'
                      ? null
                      : pushRoute(index)
                  }
                  className={
                    pathname === link.link
                      ? 'active'
                      : index <
                        filteredLinks.findIndex(
                          value => value.link === pathname
                        )
                      ? 'completed'
                      : ''
                  }
                  style={{ minWidth: '22rem' }}
                >
                  {window.innerWidth <= responsive.smaller ? '' : link.name}
                  {index <
                    filteredLinks.findIndex(
                      value => value.link === pathname
                    ) && (
                    <Check
                      className='check'
                      sx={{
                        transform: 'scale(1.5)',
                        '& > path': { fill: 'green' }
                      }}
                    />
                  )}
                </span>
              ))}
            </div>

            {!pathname.includes('/shop-and-ship-welcome') ? (
              <div className='new-shipping-steps-layout__body__progress'>
                <LinearProgress
                  variant='determinate'
                  value={Math.round(
                    ((filteredLinks
                      .filter(
                        link => !link.link.includes('/shop-and-ship-welcome')
                      )
                      .findIndex(value => value.link === pathname) +
                      1) /
                      filteredLinks.length) *
                      100
                  )}
                />
              </div>
            ) : null}
            <div className='new-shipping-steps-layout__body__content'>
              <h3 className='mobile-header'>{mobileTitle}</h3>

              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopNShipUpdateLayout;
