import React, { useState, useEffect } from 'react';
import { shopNShipResultState } from '../recoil/atoms';
import { useRecoilState } from 'recoil';
import { ReactComponent as TickIcon } from '../assets/svg/sns-payment-succss-icon.svg';

const ShopNShipPaymentSuccessful = ({
  openPrompt,
  setOpenPrompt,
  setOpenBookingSuccessfulPrompt,
  setOpenPaymentMethodPrompt = () => {},
  setOpenPaymentSuccessful
}) => {
  const [shopNShipDetails] = useRecoilState(shopNShipResultState);

  const handleClose = () => {
    setOpenBookingSuccessfulPrompt(false);
    setOpenPaymentMethodPrompt(false);
    setOpenPrompt(true);
    setOpenPaymentSuccessful(false);
  };

  useEffect(() => {
    if (shopNShipDetails && Object.keys(shopNShipDetails).length !== 0) {
      localStorage.setItem('justPaid', true);
    }
  }, []);
  return (
    <div
      className={`Year__roundup-container fade${openPrompt ? ' show' : ''}`}
      style={{
        transition: 'all 0.3s ease',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <div className='payment__successful__content'>
        <TickIcon />
        <p className='payment__successful__main__text'>PAYMENT SUCCESSFUL</p>
        <p className='payment__successful__sub__text'>
          A receipt has been sent to your email. You can proceed to continue
          with your shipment.
        </p>
        <button
          onClick={() => handleClose()}
          className='payment__successful__cta'
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default ShopNShipPaymentSuccessful;
