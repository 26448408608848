import React, { useState, useEffect } from 'react';
import FileUpload from './file-upload';
import { useSendAlert } from '../utilities/send-alert';
import {
  filteredShopNShipLinksState,
  shopNShipResultState,
  warningState
} from '../recoil/atoms';

import { useGetShopnshipQuery } from '../operations/queries';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Close } from '@mui/icons-material';
import useClearLocalStorage from '../utilities/clear-localstorage';
import { useRecoilState } from 'recoil';
import { toCurrency } from '../utilities/to-currency';
import axios from 'axios';
import SubmitButton from './submit-button';
import { YellowInfo } from '../constants/asset-contants';
import { ReactComponent as InfoIcon } from '../assets/svg/info-icon-three.svg';
import DocumentUploadSkeleton from './document-upload-skeleton';
import { useHistory } from 'react-router-dom';

const ONE_MB = 1048576;

const PostBookingDocUploads = ({
  setShowTrackingModal = () => {},
  shipmentId = null,
  setPageNum = () => {}
}) => {
  const [proofOfIdLoading, setProofOfIdFileLoading] = useState(false);

  const [
    evidenceOfPurchaseLoading,
    setEvidenceOfPurchaseFileLoading
  ] = useState(false);

  const [shopNShipDetails, setShopNShipResult] = useRecoilState(
    shopNShipResultState
  );

  const [, setShow] = useRecoilState(warningState);

  const [filteredLinks] = useRecoilState(filteredShopNShipLinksState);

  const [getShopnship, getShopnshipResult] = useGetShopnshipQuery(data => {
    if (data) {
      setShopNShipResult(data?.getShopnship);
      const parsedItems = JSON.parse(data?.getShopnship?.itemDescription);
      setItems(parsedItems);
    } else {
      setItems(JSON.parse(shopNShipDetails.itemDescription));
    }
  });

  const [firstView, setFirstView] = useState();

  const [items, setItems] = useState([]);

  const history = useHistory();

  const shopnshipId = localStorage.getItem('shipmentId');

  const sendAlert = useSendAlert();

  const clearStorage = useClearLocalStorage();

  const validationSchema = yup.object().shape({
    items: yup.array().of(
      yup.object().shape({
        proofOfId: yup
          .string()
          .required('Please upload proof of identification'),
        evidenceOfPurchase: yup
          .string()
          .required('Please upload evidence of purchase')
      })
    )
  });

  const { values, touched, errors, handleSubmit, setFieldValue } = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      items: items.map(item => ({
        ...item,
        proofOfId: item.proofOfId,
        evidenceOfPurchase: item.evidenceOfPurchase
      }))
    },

    onSubmit: () => {
      const newItems = JSON.stringify(values.items);

      setShopNShipResult({
        ...shopNShipDetails,
        itemDescription: newItems
      });

      const updatedDetails = {
        ...shopNShipDetails,
        itemDescription: newItems
      };

      localStorage.setItem('shopNShipResult', JSON.stringify(updatedDetails));

      if (firstView) {
        setTimeout(() => {
          setPageNum(2);
        }, 100);
      } else {
        setTimeout(() => {
          history.push(filteredLinks[1].link);
        }, 100);
      }
    }
  });

  useEffect(() => {
    if (!firstView && Object.keys(shopNShipDetails).length !== 0) {
      const parsedItems = JSON.parse(shopNShipDetails.itemDescription);
      setItems(parsedItems);
    } else {
      const id = shopnshipId || shipmentId || shopNShipDetails.id;
      if (id) {
        getShopnship(id);
      }
    }

    setFirstView(localStorage.getItem('justPaid'));
  }, [shipmentId, shopNShipDetails.id]);

  const handleCancel = () => {
    clearStorage(() => {
      // setShowTrackingModal(false);
      history.push('/');
    });
  };

  const handleProofOfIdFileChange = (file, index) => {
    localStorage.setItem('proofOfIdFileSize', file.size);

    if (!file) {
      return sendAlert('File not provided.', 'warning');
    }

    const fileType = new RegExp('png|jpeg|jpg|webp|pdf|doc|docx');

    if (!file.type || !file?.type.match(fileType)) {
      return sendAlert('File format not supported.', 'error');
    }

    if (file.size > ONE_MB) {
      return sendAlert('Please upload a file less than 1MB', 'error');
    }

    setProofOfIdFileLoading(true);

    const url = `${process.env.REACT_APP_REST_API}/upload`;

    const formData = new FormData();

    formData.append('file', file);

    formData.append('fileName', file.name);

    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };

    axios
      .post(url, formData, config)
      .then(response => {
        setFieldValue(`items.[${index}].proofOfId`, response.data.Location);
        setProofOfIdFileLoading(false);
      })
      .catch(err => {
        sendAlert(err?.message, 'error');
        setProofOfIdFileLoading(false);
      });
  };

  const handleEvidenceOfPurchaseFileChange = (file, index) => {
    localStorage.setItem('evidenceOfPurchaseFileSize', file.size);

    if (!file) {
      return sendAlert('File not provided.', 'warning');
    }
    const fileType = new RegExp('png|jpeg|jpg|webp|pdf|doc|docx');

    if (!file.type || !file?.type.match(fileType)) {
      return sendAlert('File format not supported.', 'error');
    }

    if (file.size > ONE_MB) {
      return sendAlert('Please upload a file less than 1MB', 'error');
    }

    setEvidenceOfPurchaseFileLoading(true);

    const url = `${process.env.REACT_APP_REST_API}/upload`;
    const formData = new FormData();

    formData.append('file', file);
    formData.append('fileName', file.name);
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };

    axios
      .post(url, formData, config)
      .then(response => {
        setFieldValue(
          `items.${index}.evidenceOfPurchase`,
          response.data.Location
        );

        setEvidenceOfPurchaseFileLoading(false);
      })
      .catch(err => {
        sendAlert(err?.message, 'error');
        setEvidenceOfPurchaseFileLoading(false);
      });
  };

  const currentPage = 1;

  if (!items || items.length === 0) {
    return <DocumentUploadSkeleton />;
  }

  const handleClearField = (index, field) => {
    setFieldValue(`items.${index}.${field}`, '');
  };

  return (
    <div
      className={`tracking__id__modal-content ${
        firstView ? null : 'expand__to__full__width'
      }`}
      style={{ overflow: firstView ? 'scroll' : '' }}
    >
      {firstView ? (
        <div className='heading__text-container'>
          <p className='heading__text'>One More Thing</p>
          <Close
            background='blue'
            onClick={handleCancel}
            style={{ cursor: 'pointer' }}
          />
        </div>
      ) : (
        ''
      )}

      {firstView ? (
        <div className='progress__bar__container'>
          <div className='process__num__text'>
            <p className='progress__text'>Step {currentPage === 1 && '1'}</p>
            <p className='progress__text'> /2</p>
          </div>
          <div className='progess__bar__box'>
            <div
              className='progress__bar__half
              '
              // className={`progress__bar ${
              //   !items || items.length === 0 ? ' ' : 'progress__bar__increase'
              // }`}
            />
          </div>
        </div>
      ) : (
        ''
      )}

      <div className='heading__texts'>
        <p className='heading__sub__text-main'>
          Help us reduce your shipment processing time
        </p>
        <p className='heading__sub__text-sub'>
          Upload your proof of identification and proof of purchase{' '}
        </p>
      </div>

      <form onSubmit={handleSubmit} className='tracking__modal__items'>
        {values?.items?.map((item, index) => (
          <div className='tracking__modal__item' key={item.id}>
            <p className='modal__item__heading'>Item {index + 1}</p>
            <div className='modal__item__boxes'>
              <div className='modal__item__box'>
                <div className='item__texts__box'>
                  <p className='item__box__label__text'>ITEM CATEGORY</p>
                  <p className='item__box__value__text'>{item.category}</p>
                </div>
                <div className='item__texts__box'>
                  <p className='item__box__label__text'>ITEM VALUE</p>
                  <p className='item__box__value__text'>
                    {toCurrency(item.amountDue)}
                  </p>
                </div>
              </div>
              <div className='modal__item__box'>
                <div className='item__texts__box'>
                  <p className='item__box__label__text'>QUANTITY</p>
                  <p className='item__box__value__text'>{item.quantity}</p>
                </div>
                <div className='item__texts__box'>
                  <p className='item__box__label__text'>WEIGHT</p>
                  <p className='item__box__value__text'>
                    {item.estimatedWeight} KG
                  </p>
                </div>
              </div>
            </div>
            <label htmlFor='proofOfId' className='document__labels'>
              <p className='file__name__label'>
                Upload Proof Of Identification
              </p>
              <p className='file__name'>
                (Government-issued ID e.g NIN, Drivers License, Voter’s License,
                Passport)
              </p>
            </label>

            <FileUpload
              onUploadFile={file => handleProofOfIdFileChange(file, index)}
              uploadedFile={item?.proofOfId}
              loading={proofOfIdLoading}
              proofOfId={true}
              onClearField={handleClearField}
              index={index}
            />
            <small
              style={{
                fontSize: '1.4rem',
                color: 'red',
                marginTop: '1.2rem'
              }}
            >
              {touched.items?.[index]?.proofOfId &&
                errors.items?.[index]?.proofOfId}
            </small>

            <div id='input-control'>
              <label
                htmlFor='evidenceOfPurchase'
                className='document__labels-two'
              >
                <p
                  style={{ marginBottom: '10px' }}
                  className='file__name__label'
                >
                  Upload Evidence of Purchase
                </p>
                <p className='file__name'>
                  (Bank statement or detailed transaction receipt)
                </p>
              </label>
              <div className='data__validity-info--box'>
                <InfoIcon />
                <p className='data__validity-info'>
                  <strong>Please note:</strong> The name on the proof of
                  purchase should match the name on your uploaded ID
                </p>
              </div>
              <FileUpload
                onUploadFile={file =>
                  handleEvidenceOfPurchaseFileChange(file, index)
                }
                uploadedFile={item?.evidenceOfPurchase}
                loading={evidenceOfPurchaseLoading}
                onClearField={handleClearField}
                index={index}
                values={values}
              />
              <small
                style={{
                  fontSize: '1.4rem',
                  color: 'red',
                  marginTop: '1.2rem'
                }}
              >
                {touched.items?.[index]?.evidenceOfPurchase &&
                  errors.items?.[index]?.evidenceOfPurchase}
              </small>
            </div>
            <div className='new-shop-n-ship__notice'>
              <img src={YellowInfo} alt='' />
              <p>
                Upload a valid proof of purchase to reduce shipment processing
                time. There will be a two-week holding period for high-value
                items delivered to our addresses without a valid proof of
                purchase.
              </p>
            </div>
          </div>
        ))}
        <div className='modal__ctas'>
          <button
            className='modal__cta modal__cancel__cta'
            type='button'
            onClick={firstView ? handleCancel : setShow}
          >
            {firstView ? 'Save & Upload Later' : 'Cancel'}
          </button>
          <SubmitButton
            className='modal__cta modal__complete__cta'
            type='submit'
            text={'Continue'}
          />
        </div>
      </form>
    </div>
  );
};

export default PostBookingDocUploads;
